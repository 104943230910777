import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import { omit } from 'lodash-es'

import { service } from '../utils'

const ContentType = 'image/jpeg'
const getUploadSign = (data: {
  file_suffix: string
  file_name: string
  content_type?: string
  u: number
  vs?: string
  hasToken?: boolean
}): any =>
  service({
    method: 'post',
    url: data?.hasToken === false ? '/hrm/other/pub/upload_sign' : '/hyb/oss/pri/upload_sign',
    data: omit(data, ['hasToken'])
  })

const toUpload = async (_params: any) => {
  const u = Date.now()
  const { success, data, msg } = await getUploadSign({
    file_suffix: 'jpeg',
    file_name: _params.fileName,
    content_type: 'image/*',
    u,
    vs: CryptoJS.MD5(`HYB:${u}`).toString(),
    hasToken: _params.hasToken
  })
  if (!success) {
    throw new Error('Upload Failed:' + msg)
  }
  const formData = new FormData()
  const dataMap = {
    ...data,
    // 分别添加每个键值对
    OSSAccessKeyId: data.access_id,
    signature: data.signature,
    key: data.object_key,
    Signature: data.signature,
    success_action_status: '200',
    'x-oss-meta-file-name': _params.file.name,
    'x-oss-content-type': 'image/*'
  }
  // 添加其他数据
  Object.keys(dataMap).forEach((key: any) => {
    console.log('formData.', key, dataMap[key])
    formData.append(key, dataMap[key])
  })
  // 添加文件
  formData.append('file', _params.file, _params.file.name)
  await fetch(data.host, {
    // 替换为实际的上传接口地址
    method: 'POST',
    body: formData
  }).catch((error) => {
    console.error('Upload error:', error)
  })
  return data.object_key
}
function compressImage(myCanvas: any, format: any) {
  const imgCanvas = document.createElement('canvas')
  const imgCtx = imgCanvas.getContext('2d') as any
  const width = myCanvas.width
  const height = myCanvas.height
  const maxSide = 800
  const scaleFactor = maxSide / Math.max(width, height)
  const scaledWidth = width * scaleFactor
  const scaledHeight = height * scaleFactor
  imgCanvas.width = scaledWidth
  imgCanvas.height = scaledHeight
  console.log('new scaleWIdth:', scaledWidth, scaledHeight)
  imgCtx.fillStyle = 'white'
  imgCtx.fillRect(0, 0, scaledWidth, scaledHeight)
  imgCtx?.drawImage(myCanvas, 0, 0, scaledWidth, scaledHeight)
  return imgCanvas
}
const fileToImage = (file: File) => {
  return new Promise((resolve, reject) => {
    // 创建一个指向文件的URL
    const url = URL.createObjectURL(file)

    // 创建一个新的Image对象
    const img = new Image()

    // 设置Image对象的src属性，并监听load事件
    img.onload = () => {
      // 当图片加载完成后，可以在这里进行其他操作，比如绘制到canvas上等
      console.log('Image loaded:', img)

      // 如果你想保留这个图片对象以便后续使用，可以更新state
      resolve(img)

      // 记得释放不再使用的URL
      URL.revokeObjectURL(url)
    }

    img.onerror = () => {
      // 如果加载失败，你可以在这里处理错误
      console.error('Failed to load image:', url)
      reject(new Error('Failed to load image'))
    }

    // 开始加载图片
    img.src = url
  })
}

/**
 * canvas|image 转 Blob 并上传
 * @param myCanvas HTMLCanvasElement | HTMLImageElement
 * @returns
 */
export default async (input: HTMLCanvasElement | HTMLImageElement | File, hasToken?: boolean) => {
  let newInput: any = input
  if (input instanceof File) {
    newInput = await fileToImage(input)
  }
  const fileName = 'compress.jpeg'
  const newCanvas: any = await compressImage(newInput, ContentType)
  return new Promise((resolve, reject) => {
    newCanvas.toBlob(async (blob: Blob) => {
      if (!blob) throw new Error('Failed to convert canvas to blob')

      try {
        // 创建一个临时文件路径（这里使用 URL.createObjectURL）
        const path = URL.createObjectURL(blob)
        const file = new File([blob], fileName, { type: ContentType })

        const object_key: string = await toUpload({ fileName, file, hasToken })
        // 可以在这里使用 tempFilePath 进行其他操作，例如上传到服务器
        console.log('Temporary file path:', path)
        // 释放 URL 对象
        URL.revokeObjectURL(path)
        resolve(object_key)
      } catch (e) {
        reject(e)
      }
    }, ContentType)
  })
}
