export enum FlowType {
  Apply,
  Approval
}

export enum FlowListType {
  Nosubmitted,
  Approval,
  // Done,
  Passed,
  Fail,
  Revoke,
  Processing,
  Processed,
  Ccmy
}

export enum FlowOperaType {
  Delete,
  Submit,
  Save,
  Detail,
  Urging,
  Revoke,
  Reject,
  Agree,
  Modify,
  Countersign,
  Transfer,
  Back,
  File,
  SubmitAgain
}

export enum ApprovalListType {
  Processing,
  Processed,
  Ccmy
}

export enum ApplyInitiate {
  Self,
  Agent
}

export enum ApplyKeyWord {
  SubmitAgain = 'SubmitAgain',
  INDIVIDUALINITIATED = 'INDIVIDUAL_INITIATED',
  AGENTINITIATED = 'AGENT_INITIATED'
}

export interface ApplyListOperaItem {
  cardAction: Array<{
    text: string
    color?: string
    flowOperaType: FlowOperaType
  }>
  status?: Array<{
    className: any
    text: string
    color?: string
    status?: string
  }>
  flowType: FlowType
  flowListType: FlowListType
}
