import { useSetState, useDeepCompareEffect, useRequest } from 'ahooks'

import { queryDataList } from '@/service'
import './index.less'
/**
 * 按钮组
 */
export default ({
  value,
  onConfirm,
  dataList = [],
  fieldNames = { value: 'value', label: 'name' },
  mode = 'single', // multiple | single
  popup = 'array', // string | array
  url = '',
  method = 'post',
  isChildren = false, // 是否只要Children数据
  initArgument = {}
}: {
  url?: string
  method?: string
  isChildren?: boolean
  initArgument?: any
  value?: any
  onConfirm: (value: any) => void
  dataList: any[]
  popup: string
  mode: string
  fieldNames: any
}) => {
  const [{ buttonValue, dataNewList }, setState] = useSetState<any>({
    buttonValue: null,
    dataNewList: []
  })
  useDeepCompareEffect(() => {
    if (mode === 'multiple') {
      setState({ buttonValue: value || null })
    } else if (mode === 'single') {
      const button_value = popup === 'string' ? value : value?.[0]
      setState({ buttonValue: button_value || null })
    }
  }, [value])
  useDeepCompareEffect(() => {
    setState({ dataNewList: dataList || [] })
  }, [dataList])
  useDeepCompareEffect(() => {
    if (url) {
      runQueryDataList(initArgument, method, url)
    }
  }, [url])
  const { run: runQueryDataList } = useRequest(queryDataList, {
    manual: true,
    onSuccess: (result: any) => {
      const { data } = result
      console.log('runQueryDataList---isChildren', isChildren)
      let flowTreeFlat = data || []
      if (isChildren) {
        flowTreeFlat = data?.map((x: any) => x.children)?.flat() || []
      }
      setState({ dataNewList: flowTreeFlat })
    },
    onError: (_: any) => {
      setState({ options: [] })
    }
  })
  const slectedItem = (id: number) => {
    let button_value = null
    if (mode === 'multiple') {
      let data = buttonValue || []
      const exist: boolean = buttonValue?.length ? (buttonValue || [])?.includes(id) : false
      if (exist) {
        data = buttonValue?.filter((x: any) => x !== id)
      } else data.push(id)
      button_value = data?.length ? data : null
    } else if (mode === 'single') {
      if (popup === 'string') {
        button_value = buttonValue === id ? null : id
      } else if (popup === 'array' && id !== null) {
        button_value = String(buttonValue) === String(id) ? null : [id]
      }
    }
    onConfirm(button_value)
  }
  const activeBool = (key: number | string): any => {
    if (mode === 'multiple') {
      return buttonValue?.includes(key)
    } else if (mode === 'single') {
      return buttonValue === key
    }
  }
  return (
    <div className='hyb-button-group'>
      <div className='hyb-button-group-area'>
        {dataNewList?.map((item: any) => (
          <div
            key={`${item?.[fieldNames?.value]}`}
            className={`
                  hyb-button-group-area-item
                  hyb-button-group-area-item-${activeBool(item?.[fieldNames?.value]) ? 'active' : ''}`}
            onClick={() => slectedItem(item?.[fieldNames?.value])}
          >
            {item?.[fieldNames?.label]}
          </div>
        ))}
      </div>
    </div>
  )
}
