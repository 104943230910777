import { Toast } from 'antd-mobile'
import { cloneDeep } from 'lodash-es'
import { useLocation } from 'react-router-dom'

import mapping from '@/components/NewEditForm/mapping'
import { isPrdEnv } from '@/utils'
import logger from '@/utils/logger'

const previewInject: Record<string, any> = {
  hide: false,
  required: false,
  disabled: false,
  OTHER: (e: any) => e
}

const IgnoreInject: Record<string, any> = {
  ...previewInject,
  OTHER: (e: any) => {
    Toast.show('配置错误，请联系管理员')
    return e
  }
}
/**
 * 变量注入 变量=@xxx
 * @param vars 替换变量
 * @param isPreview 是否预览,为true时统一替换成()=>{}
 * @returns { injectVariable }
 */
export default function ({ vars, isPreview }: { vars: any; isPreview?: boolean }) {
  const loc = useLocation()
  // 使用表达式取值 变量1.变量2...
  const toExpressionValue = ({ key, data }: any) => {
    const columns = key.split('.')
    const pick = columns.reduce((pre: any, cur: any) => {
      if (pre?.[cur] !== null && pre?.[cur] !== undefined) {
        return pre[cur]
      }
      return null
    }, data)
    return pick
  }
  const injectVariable = (option: any, newVars?: any) => {
    const _vars = { ...vars, ...newVars }
    console.log('inject _vars:', _vars)
    const _option = cloneDeep(option)
    const deepObj = (obj: any) => {
      if (Array.isArray(obj)) {
        obj.forEach((item) => deepObj(item))
      } else if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            // string to 可执行代码
            if (typeof obj[key] === 'string' && obj[key].startsWith('@js:')) {
              const str = JSON.parse(JSON.stringify(obj[key]))
              const var_key = str.substring(4)
              try {
                // eslint-disable-next-line no-new-func
                const func = new Function(`return ${var_key}`)
                obj[key] = func
              } catch (error) {
                throw new Error(`注入错误！函数格式有误${error}`)
              }
            } else if (typeof obj[key] === 'string' && obj[key].startsWith('@')) {
              const str = JSON.parse(JSON.stringify(obj[key]))
              const var_key = str.substring(1)
              const fromOption = var_key.match(/\./)
              // 可以从option中取值
              if (fromOption) {
                obj[key] = toExpressionValue({
                  key: var_key,
                  data: { ...option, ...mapping, ..._vars }
                })
              } else if (obj[key]) {
                // 从vars中取值
                obj[key] = isPreview
                  ? previewInject[var_key] || previewInject.OTHER
                  : _vars[var_key]
              }
              if (obj[key] === null || obj[key] === undefined) {
                const error = new Error(
                  `JSON配置错误！没有找到${str}，请检查vars传入参数\n[${loc.pathname}]`
                )
                if (isPrdEnv) {
                  obj[key] = IgnoreInject[key] || IgnoreInject.OTHER
                  console.error(error)
                  logger(error)
                } else {
                  throw error
                }
              }
            } else deepObj(obj[key])
          }
        }
      }
    }
    deepObj(_option)
    console.log('inject option:', _option)
    return _option
    // const optionType = isTypeof(option)
    // if (optionType.isArray) {
    //   return option.map((item: any) => injectVariable(item))
    // } else if (optionType.isObject) {
    //   return Object.keys(option).reduce((injected: any, key: string) => {
    //     injected[key] = injectVariable(option[key])
    //     return injected
    //   }, {})
    // } else if (optionType.isString) {
    //   console.log(option, '7777')
    //   return replace(option)
    // }
    // return option
  }

  return injectVariable
}
