import { useSetState } from 'ahooks'
import { Picker } from 'antd-mobile'
import classNames from 'classnames'
import { useLocalStore, observer } from 'mobx-react'
import React, { PropsWithChildren, useEffect, useRef } from 'react'

import dayjs from '@/utils/dayjs'

import { AllSetting } from './constant'
import useColumns from './hooks/useColumns'
import comstore from './store'

import './index.less'

interface ScribeLineItemProps {
  value?: any
  type?: string
  visible?: boolean
  source?: string
  currentDay?: string
  title?: string
  // 1固定班，2划线班
  shiftType?: number
  onChange: (value: any) => void
  onClose?: (value: boolean) => void
  renderChildren?: (value: any[]) => void
  [key: string]: any
}

type TimePickerProps = ScribeLineItemProps & PropsWithChildren

function TimePicker({
  value,
  onChange,
  visible: _visible,
  type,
  source,
  onClose,
  currentDay,
  shiftType,
  title = '请选择上下班时间',
  children,
  disabled,
  readed,
  renderChildren,
  ...rest
}: TimePickerProps) {
  const endPickerRef = useRef<any>()
  const [{ visible, display, startIndex }, setState] = useSetState<any>({
    visible: false,
    startIndex: 0
  })
  const { runScheduleSetting, setting: storeSetting } = useLocalStore(() => comstore)
  const { startColumns, endColumns } = useColumns({
    dataSource: {
      start_datetime: '',
      end_datetime: ''
    },
    setting: shiftType === 1 ? AllSetting : storeSetting,
    endPickerRef,
    open,
    todayStr: currentDay,
    startIndex
  })

  const columns = [startColumns, endColumns]

  useEffect(() => {
    runScheduleSetting()
  }, [])

  useEffect(() => {
    if (display) onChange?.(display)
  }, [display])

  useEffect(() => {
    if (source && source === 'Modal') {
      setState({
        visible: _visible
      })
    }
  }, [_visible])

  useEffect(() => {
    // value && onChange?.(undefined)
  }, [currentDay])

  const bool: boolean = value && Array.isArray(value) && value?.length > 0

  const renderCell = (props: any) => {
    const { split, className } = props
    return (
      <div className={classNames(className)}>
        <span>
          {bool ? (value?.[0]?.split(' ')?.[0] !== currentDay ? ' 次日' : '') : ''}
          {bool ? value?.[0]?.split(' ')?.[1] || '' : ''}
        </span>

        {bool ? (
          split || '-'
        ) : (
          <span className={classNames({ 'items-timepicker-value-split': true })}>请选择</span>
        )}
        <span>
          {bool ? (value?.[1]?.split(' ')?.[0] !== currentDay ? ' 次日' : '') : ''}
          {bool ? value?.[1]?.split(' ')?.[1] || '' : ''}
        </span>
      </div>
    )
  }

  const _bool: boolean = !disabled && !readed

  return (
    <>
      {source !== 'Modal' && (
        <div
          className={classNames('items-timepicker-value', {
            // 'items-timepicker-value-disabled': disabled
          })}
          onClick={() => _bool && setState({ visible: true })}
        >
          {renderChildren?.(renderCell) || renderCell({})}
        </div>
      )}
      <Picker
        columns={columns}
        visible={visible}
        title={title}
        onClose={() => (onClose ? onClose?.(false) : setState({ visible: false }))}
        value={value || []}
        onConfirm={(v) => {
          setState({ display: v, visible: false })
        }}
        onSelect={(e: any) => {
          setState({ startIndex: startColumns?.findIndex((x: any) => x.value === e?.[0]) })
        }}
        className='time-picker'
      />
    </>
  )
}

/**
 * 前置化转换 - 用于回显
 * @param value
 * @param config
 * @returns newValue
 */
TimePicker.convertValue = (value: any, config?: any, values?: any) => {
  if (Array.isArray(config?.names) && config?.names?.length === 4) {
    if (
      !values[config?.names[0]] ||
      !values[config?.names[1]] ||
      !values[config?.names[2]] ||
      !values[config?.names[3]]
    ) {
      return undefined
    }
    const nextDate = dayjs(config?.currentDay).add(1, 'd').format('YYYY-MM-DD')
    const startDate = values[config?.names[0]] === 'NEXT_DAY' ? nextDate : config?.currentDay
    const endDate = values[config?.names[2]] === 'NEXT_DAY' ? nextDate : config?.currentDay
    const range = [
      `${startDate} ${values[config?.names[1]]}`,
      `${endDate} ${values[config?.names[3]]}`
    ]
    return { [config?.name]: range }
  }
  if (!Array.isArray(value) || value.length !== 2 || !value[0] || !value[1]) {
    return undefined
  }
  return value
}
const getDateObject = (datetime: any, date: any) => {
  if (date && Array.isArray(datetime) && datetime.length === 2) {
    return [
      datetime[0]?.startsWith(date) ? 'CURRENT_DAY' : 'NEXT_DAY',
      datetime[0]?.substring(11),
      datetime[1]?.startsWith(date) ? 'CURRENT_DAY' : 'NEXT_DAY',
      datetime[1]?.substring(11)
    ]
  }
  return []
}
/**
 * 提交时转换
 * @param value
 * @param config
 * @returns newValue
 */
TimePicker.transform = (value: any, config?: any) => {
  if (
    Array.isArray(config?.names) &&
    config?.names?.length === 4 &&
    Array.isArray(value) &&
    value?.length === 2
  ) {
    const dateObject = getDateObject(value, config?.currentDay)
    if (dateObject?.length === 4) {
      return {
        [config?.names[0]]: dateObject?.[0],
        [config?.names[1]]: dateObject?.[1],
        [config?.names[2]]: dateObject?.[2],
        [config?.names[3]]: dateObject?.[3]
      }
    }
  }
  return value
}

export default observer(TimePicker)
