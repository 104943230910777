// import Taro from '@tarojs/taro'
import { observable, makeAutoObservable, action } from 'mobx'

import { commonNav } from '@/utils'

import { ObjectToKeyValueString, mapSchedule } from '../Contants'

import {
  workFlowTreeApi,
  workFlowApplyApi,
  flowDetailApi,
  workFlowAppravalApi,
  appendNodeApi,
  transferNodeApi,
  revertNodeApi,
  approvalOperateApi,
  applyUnreadCountApi,
  approvalUnreadCountApi,
  organizationsChild,
  getScheduleList,
  getEmployeeSchedule
} from '../services'
import { FlowOperaType, FlowType, ApplyKeyWord } from '../types'

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  @observable type: string = '' // 项目code

  @observable employee: any = {}

  @observable requestParams = {}

  @observable show: boolean = false

  @observable approvalFormShow: boolean = false

  @observable approvalForm: any = {}

  @observable flowDialogForm: any = {}

  @observable applyInitiate: ApplyKeyWord = ApplyKeyWord.INDIVIDUALINITIATED
  @observable flowConfrimShow: boolean = false //
  @observable applyType: any = null

  @observable flowTree: any = []

  @observable flowTreeFlat: any[] = []

  @observable selectCurrentProjectTree: any = {}

  @observable flowApplyData: any = {}

  @observable flowApplyLoading: boolean | null = null

  @observable flowDetail: any = {}

  @observable flowApprovalData: any = {}

  @observable errorMsg: any = {}

  @observable applyUnreadData: any = {}

  @observable approvalUnreadData: any = {}

  @observable reshuffleStatus: string = ''

  @observable reshuffle: any = []

  @observable organizationsList: any[] = []

  @observable schduleList: any[] = []

  @observable employeeSchedule: any[] = []

  @observable changeEmployeeSchedule: any[] = []

  @observable flowTableItem: Record<string, any> = {}

  @action changeType = (code: string) => {
    this.type = code
  }

  @action changeShow = (show: boolean) => {
    this.show = show
  }

  @action changeApplyInitiate = (applyInitiate: string | any) => {
    this.applyInitiate = applyInitiate
  }

  @action changeEmployee = (employee: any) => {
    this.employee = { ...this.employee, ...employee }
  }

  @action clearEmployee = () => {
    this.employee = {}
  }

  @action changeRequestParams = (requestParams: Record<string, any>) => {
    this.requestParams = requestParams
  }

  @action changeErrorMsg = (errorMsg: any) => {
    this.errorMsg = errorMsg
  }

  @action changeApprovalFormShow = (approvalFormShow: boolean) => {
    this.approvalFormShow = approvalFormShow
  }

  @action changeApprovalForm = (approvalForm: any = {}) => {
    this.approvalForm = approvalForm
  }

  @action changeFlowDialogForm = (flowDialogForm: any = {}) => {
    this.flowDialogForm = flowDialogForm
  }

  @action changeFlowConfrimShow = (flowConfrimShow: boolean) => {
    this.flowConfrimShow = flowConfrimShow
  }

  @action changeFlowTableItem = (flowTableItem: any) => {
    this.flowTableItem = flowTableItem
  }

  @action flowApprovalCardCallback = (data: any) => {
    const { btnInfo, dataSource, flowOperaType, flowListType } = data
    const params = {
      flowType: FlowType.Approval,
      flowOperaType,
      flowListType
    }
    if (flowOperaType === FlowOperaType.Detail) {
      this.changeType('')
      commonNav.navigateTo({
        url: `/flow/applyForm?${ObjectToKeyValueString({
          ...params
        })}`
      })
      this.changeFlowTableItem(dataSource)
    }
    if (flowOperaType === FlowOperaType.Revoke) {
      this.changeFlowDialogForm(data)
      this.changeFlowConfrimShow(true)
    }
    if (
      flowOperaType === FlowOperaType.Agree ||
      flowOperaType === FlowOperaType.Reject ||
      flowOperaType === FlowOperaType.Countersign ||
      flowOperaType === FlowOperaType.Back ||
      flowOperaType === FlowOperaType.Transfer ||
      flowOperaType === FlowOperaType.Modify
    ) {
      this.changeApprovalForm(data)
      // this.changeApprovalForm({
      //   text,
      //   operaType,
      //   item: dataSource || item,
      //   flowOperaType,
      //   flowListType: 5,
      // })
      // console.log(flowOperaType, 'flowOperaType', dataSource, item, toJS({
      //   text,
      //   operaType,
      //   item: dataSource || item,
      //   flowOperaType,
      //   flowListType: 5
      // }))
      this.changeApprovalFormShow(true)
    }
  }

  @action handleApprovalForm = async (params: any) => {
    const {
      wx = 0,
      fieldValues,
      approvalForm: {
        flowOperaType,
        // item,
        items = [],
        item: { inst_node_id, workflow_inst_id, apply_id, inst_id, title }
      }
    } = params
    const datas = items.map((x: any) => ({
      // 批量
      id: x.apply_id,
      inst_id: x?.inst_id,
      title: x.title,
      inst_node_id: x.inst_node_id
    }))
    const params_data = {
      data: datas.length
        ? datas
        : [
            {
              id: apply_id,
              inst_id: inst_id || workflow_inst_id,
              title,
              inst_node_id
            }
          ],
      ...fieldValues
    }
    if (flowOperaType === FlowOperaType.Agree) {
      const { data, code } = await approvalOperateApi({
        ...params_data,
        approve_type: 3,
        employee_ids: params_data?.employee_ids?.map((x: any) => x.id) || []
      })
      if (code !== 2000) return
      if (Array.isArray(data) && data.length > 0) return this.changeErrorMsg({ show: true, data })
      commonNav.navigateBack()
    }
    if (flowOperaType === FlowOperaType.Reject) {
      const { data, code } = await approvalOperateApi({
        ...params_data,
        approve_type: 4
      })
      if (code !== 2000) return
      if (Array.isArray(data) && data.length > 0) return this.changeErrorMsg({ show: true, data })
      commonNav.navigateBack()
    }

    if (flowOperaType === FlowOperaType.Countersign) {
      const _params = {
        ...fieldValues,
        approver_ids: fieldValues.transfer_approver_ids.map((x: any) => x.id),
        workflow_inst_id
      }
      delete _params.transfer_approver_ids
      const { data, code } = await appendNodeApi(_params)
      if (code !== 2000) return
      if (Array.isArray(data) && data.length > 0) return this.changeErrorMsg({ show: true, data })
      commonNav.navigateBack()
    }

    if (flowOperaType === FlowOperaType.Transfer) {
      const { data, code } = await transferNodeApi({
        ...fieldValues,
        transfer_approver_ids: fieldValues.transfer_approver_ids.map((x: any) => x.id),
        workflow_inst_id,
        inst_node_id
      })
      if (code !== 2000) return
      if (Array.isArray(data) && data.length > 0) return this.changeErrorMsg({ show: true, data })
      commonNav.navigateBack()
    }

    if (flowOperaType === FlowOperaType.Back) {
      const { data, code } = await revertNodeApi({
        ...fieldValues,
        workflow_inst_id,
        inst_node_id
      })
      if (code !== 2000) return
      if (Array.isArray(data) && data.length > 0) return this.changeErrorMsg({ show: true, data })
      commonNav.navigateBack()
    }

    // if (flowOperaType === FlowOperaType.Modify) {
    //   const { data, code } = await applicationLeaveApi({
    //     ...item,
    //     reason: fieldValues.comment,
    //     type: apply_category_code
    //   })
    //   if (code !== 2000) return
    //   if (Array.isArray(data) && data.length > 0) return this.changeErrorMsg({ show: true, data })
    //   Taro.navigateBack()
    // }
  }

  @action flowApplyCardCallback = async (data: any) => {
    const { item, ...flowparams } = data
    const { flowOperaType, flowListType } = flowparams
    if (flowOperaType === FlowOperaType.Detail) {
      this.changeType('')
      const url_value = ObjectToKeyValueString({
        ...flowparams
      })
      this.changeFlowTableItem(item)
      commonNav.navigateTo({ url: `/flow/applyForm?${url_value}` })
    }

    if (
      flowOperaType === FlowOperaType.Delete ||
      flowOperaType === FlowOperaType.Submit ||
      flowOperaType === FlowOperaType.Revoke ||
      flowOperaType === FlowOperaType.Urging ||
      flowOperaType === FlowOperaType.Save
    ) {
      this.changeFlowDialogForm(data)
      this.changeFlowConfrimShow(true)
    }

    if (flowOperaType === FlowOperaType.File) {
      this.changeApprovalForm(data)
      this.changeApprovalFormShow(true)
    }
    if (flowOperaType === FlowOperaType.Save) {
      console.log('存入草稿')
    }
  }

  @action handleApplyForm = () => {}

  @action handleApplyType = (count: any) => {
    this.applyType = count
  }
  @action fetchWorkFlowTree = async (apply_mode: any, bool?: boolean) => {
    const { data } = await workFlowTreeApi({
      enable_status: 1,
      query_type: bool ? null : apply_mode === 'INDIVIDUAL_INITIATED' ? 1 : 2
    })
    this.flowTree = data
    const workFlow: any = []
    data?.forEach((x: any) => {
      x?.children?.forEach((j: any) => {
        workFlow.push(j)
      })
    })
    this.flowTreeFlat = workFlow
    // callback?.(workFlow, data)
  }
  @action changeSelectCurrentProjectTree = (selectCurrentProjectTree: any) => {
    this.selectCurrentProjectTree = selectCurrentProjectTree
  }

  @action fetchWorkFlowApplyApi = async (params: any) => {
    this.flowApplyLoading = true
    const { data, total } = await workFlowApplyApi(params)
    this.flowApplyLoading = false
    this.flowApplyData = {
      flowApplyList: Array.from(
        new Map(
          [...(this.flowApplyData?.flowApplyList || []), ...data].map((item) => [
            item.apply_id,
            item
          ])
        ).values()
      ),
      total
    }
  }

  @action resetFlowApplyData = () => {
    this.flowApplyData = {}
    this.employeeSchedule = []
    this.changeEmployeeSchedule = []
  }

  @action fetchFlowDetailApi = async (id: any, params: Record<string, number>) => {
    const { data } = await flowDetailApi(id, params)
    this.flowDetail = data
  }

  @action resetFlowApplyDetail = () => {
    this.flowDetail = {}
  }

  @action changeFlowApplyDetail = (flowDetail: any = {}) => {
    this.flowDetail = flowDetail
  }

  @action fetchWorkFlowApprovalApi = async (params: any) => {
    const { data, total } = await workFlowAppravalApi(params)
    this.flowApprovalData = {
      flowApprovalList: Array.from(
        new Map(
          [...(this.flowApprovalData?.flowApprovalList || []), ...data].map((item) => [
            item.inst_node_id,
            item
          ])
        ).values()
      ),
      total
    }
  }

  @action fetchOrganizationsChild = async () => {
    const { code, data } = await organizationsChild({
      enable_status: 1,
      with_no_auth: true
    })
    if (code === 2000) {
      this.organizationsList = [data]
    }
  }

  @action resetFlowApprovalData = () => {
    this.flowApprovalData = {}
  }

  @action changeApplyUnreadData = async () => {
    const { data } = await applyUnreadCountApi()
    this.applyUnreadData = data
  }

  @action changeApprovalUnreadData = async () => {
    const { data } = await approvalUnreadCountApi()
    this.approvalUnreadData = data
    const keys = Object.keys(data)
    return keys.reduce((res, key) => {
      const currentIndex = Number(key.split('count')[1])
      res[(currentIndex - 1) as number] = data[key] as number
      return res
    }, [] as number[])
  }

  @action changeReshuffleStatus = (reshuffleStatus: string) => {
    this.reshuffleStatus = reshuffleStatus
  }

  @action changeReshuffle = (reshuffle: any[]) => {
    this.reshuffle = reshuffle
  }

  @action fetchSchduleList = async () => {
    const { data, code } = await getScheduleList()
    if (code === 2000) {
      this.schduleList = data?.map((x: any) => ({ ...x, label: x?.name, value: x?.id }))
    }
  }

  @action fetchEmployeeSchedule = async (params: any, type: string) => {
    const { data, code } = await getEmployeeSchedule(params)
    if (code === 2000 && type === 'employeeSchedule') {
      this.employeeSchedule = data?.map(mapSchedule) || []
    }
    if (code === 2000 && type === 'changeEmployeeSchedule') {
      this.changeEmployeeSchedule = data?.map(mapSchedule)
    }
  }
}
export default new Store()
