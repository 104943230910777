import { ActionSheet, Checkbox } from 'antd-mobile'
import classNames from 'classnames'
import { useLocalObservable } from 'mobx-react-lite'

import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'
import authStore from '@/store/global/authStore'
import { getTypeof, isNotEmptyArray } from '@/utils'

import { type Data, execJsStr, textRender } from './utils'

import './index.less'

const getFieldName = (item: any, fieldNames: any, field: string) => {
  const column = fieldNames?.[field]
  const matched = item?.[column || '']
  switch (getTypeof(matched)) {
    case 'Array':
      return matched?.map((i: any) => i || '').join('；')
    default:
      return matched || ''
  }
}

const CardItem = ({ dataSource = {}, index, refresh, ...restProps }: any) => {
  const { getCodeFun } = useLocalObservable(() => authStore)
  const { open, onOpen, onCancel } = usePopup({})

  const {
    status_code,
    status_list,
    status_name_code,
    isRowSelection,
    row_selection_code = 'id',
    btn_list: btnList,
    table_columns: tableColumns,
    onClick,
    renderCardItemTitle,
    renderCardItemRow,
    renderCardItemTips,
    fieldNames
  } = restProps

  const CardItemRow = ({
    item,
    type = 'label'
  }: {
    type: string
    item: {
      valueRender?: (x: any, y: any) => string
    } & Data
  }) => {
    const {
      show,
      suffix = '',
      hide,
      title,
      dataIndex,
      className = '',
      render,
      options
    } = item || {}

    // 是否展示
    const isShow =
      (!show || (show && execJsStr(show, dataSource))) && (!hide || !execJsStr(hide, dataSource))
    const tempValue = textRender(dataIndex as string, dataSource)
    const valueStr =
      typeof render === 'function'
        ? render(item, dataSource)
        : Array.isArray(options) && options.length
          ? options?.find((i: any) => i.value === tempValue)?.label
          : `${tempValue}${tempValue !== '-' ? suffix : ''}`

    const isLabel = type === 'label'
    return (
      isShow && (
        <div className='hyb-card-item-row'>
          {title && isLabel && (
            <>
              <div className='hyb-card-item-row-label'>{`${title}`}</div>
              <span>：</span>
            </>
          )}
          {!isLabel && (
            <div className={`hyb-card-item-row-value van-ellipsis-1 ${className}`}>
              {valueStr || '-'}
            </div>
          )}
        </div>
      )
    )
  }

  // Card button
  const CardItemButton = ({ item, dataSource }: { item: Data; dataSource: Data }) => {
    const { text, className = '', onClick, color, ...rest } = item || {}
    return (
      <div
        className={`hyb-card-item-ft-button ${className}`}
        style={{ color }}
        onClick={() => onClick({ btnInfo: item, dataSource })}
      >
        {text}
      </div>
    )
  }

  // 状态是否展示
  const statusVisible = !!status_name_code
  const currentStatusName = dataSource[status_name_code]
  // 当前状态数据
  const currentStatusInfo = (() => {
    if (!statusVisible) {
      return {}
    }
    const currentStatusCode = String(dataSource[status_code])
    return currentStatusCode && isNotEmptyArray(status_list)
      ? status_list.find((statusItem: any) => String(statusItem.code) === currentStatusCode) || {}
      : {}
  })()

  // 动作指令
  const {
    hasActionSheet = false,
    defaultBtnList = [],
    actionBtnList = []
  } = (() => {
    // 过滤有权限的按钮
    const tempBtnList = (
      isNotEmptyArray(btnList) ? btnList.filter((x: any) => getCodeFun(x.code)) : []
    ).filter((btnItem: any, index: number) => {
      const { show } = btnItem || {}
      // 是否展示
      return !show || (show && execJsStr(show, dataSource))
    })

    if (!isNotEmptyArray(tempBtnList)) {
      return {}
    }
    // 是否支持动作面板
    const hasActionSheet = isNotEmptyArray(tempBtnList) && tempBtnList.length > 4

    if (!hasActionSheet) {
      return {
        hasActionSheet,
        defaultBtnList: tempBtnList
      }
    }

    // 组合更多按钮
    const defaultBtnList = [
      ...tempBtnList.slice(0, 3),
      {
        text: '更多',
        onClick: onOpen
      }
    ]

    return {
      hasActionSheet,
      defaultBtnList,
      actionBtnList: tempBtnList.slice(3).map((btnItem: any, index: number) => ({
        ...btnItem,
        key: `${btnItem.code}_${index}`
      }))
    }
  })()

  const actionSheetProps = {
    visible: open,
    cancelText: '取消',
    onClose: onCancel
  }

  const justStatus = () => {
    const title =
      typeof renderCardItemTitle === 'function'
        ? renderCardItemTitle(dataSource)
        : getFieldName(dataSource, fieldNames, 'title')
    return !title && statusVisible
  }

  const handleClick = () => {
    typeof onClick === 'function' &&
      onClick({
        index,
        btnInfo: {
          text: '详情'
        },
        dataSource
      })
  }

  return (
    <div className='hyb-card-item'>
      {!status_name_code && !isRowSelection && !renderCardItemTitle ? null : (
        <div
          className={classNames('hyb-card-item-hd', { justStatus: justStatus() })}
          onClick={handleClick}
        >
          <div className='hyb-card-item-hd-lt'>
            {isRowSelection && (
              <Checkbox
                className='hyb-card-item-checkbox'
                value={dataSource[row_selection_code]}
                onClick={(e) => e.stopPropagation()}
              />
            )}
            <div className='hyb-card-item-title'>
              {typeof renderCardItemTitle === 'function'
                ? renderCardItemTitle(dataSource)
                : getFieldName(dataSource, fieldNames, 'title')}
            </div>
          </div>
          {/* 状态 */}
          {statusVisible ? (
            <div className='hyb-card-item-hd-gt'>
              <div
                className={`hyb-card-item-status ${currentStatusInfo?.className || ''}`}
                style={{ color: currentStatusInfo.color || '' }}
              >
                {currentStatusInfo.text || currentStatusName || ''}
              </div>
            </div>
          ) : null}
        </div>
      )}
      {/* card item 信息展示 */}
      <div className='hyb-card-item-bd' onClick={handleClick}>
        {typeof renderCardItemRow === 'function'
          ? renderCardItemRow(dataSource)
          : isNotEmptyArray(tableColumns) && (
              <div className='hyb-card-item-bd-main'>
                <div className='hyb-card-item-bd-left'>
                  {tableColumns.map((rowItem: any, index: number) => {
                    return (
                      <CardItemRow
                        key={`${rowItem.dataIndex}_${index}`}
                        type='label'
                        item={rowItem}
                      />
                    )
                  })}
                </div>
                <div className='hyb-card-item-bd-right'>
                  {tableColumns.map((rowItem: any, index: number) => {
                    return (
                      <CardItemRow
                        key={`${rowItem.dataIndex}_${index}`}
                        type='value'
                        item={rowItem}
                      />
                    )
                  })}
                </div>
              </div>
            )}
        {typeof renderCardItemTips === 'function' && (
          <div className='hyb-card-item-row hyb-card-item-bd-tips'>
            {renderCardItemTips(dataSource)}
          </div>
        )}
      </div>
      {/* card 底部按钮 */}
      {isNotEmptyArray(defaultBtnList) && (
        <div className='hyb-card-item-ft'>
          {defaultBtnList.map((btnItem: any, index: number) => {
            return <CardItemButton key={index} item={btnItem} dataSource={dataSource} />
          })}
        </div>
      )}
      {/* 更多动作面板 */}
      {hasActionSheet && <ActionSheet actions={actionBtnList} {...actionSheetProps} />}
    </div>
  )
}

export default CardItem
