/* eslint-disable react-hooks/exhaustive-deps */
import { Image, ImageUploader, Toast } from 'antd-mobile'
import { useEffect } from 'react'

import { useEventManager } from '@/components/EventManagerProvider'
import UploadFile from '@/components/UploadFile'

import { batchDownloadSigns } from './service'
// eslint-disable-next-line import/order
import './index.less'

function FormImage(props: any) {
  const {
    limit = 5,
    value,
    onConfirm,
    required,
    hasToken = true,
    disabled = false,
    label = '',
    readed
  } = props
  console.log(props, 'props')
  const beforeUpload = async (file: File | any) => {
    // if (file.size > 1024 * 1024) {
    //   Toast.show('请选择小于 1M 的图片')
    //   return null
    // }
    return null
  }
  const upload = async (file: File | any) => {
    return file
  }
  const onDelete = (item: any) => {
    const img_url: any = []
    value?.forEach((item_url: any) => {
      if (item?.url !== item_url?.url) {
        img_url.push(item_url)
      }
    })
    onConfirm?.(img_url || [])
  }
  const eventManager = useEventManager()
  const showLoading = (loading: boolean) => {
    eventManager?.emit('NewEditForm.setState', { loading })
  }
  useEffect(() => {
    if (Array.isArray(value) && value?.length > 0) {
      Toast.clear()
      if (value?.findIndex((item: any) => !item?.url) > -1) {
        FormImage.convertTo(value, { limit }).then((data: any) => {
          onConfirm(data)
        })
      }
    }
  }, [value])
  // console.log('img_url:::', props)
  const bool: boolean = !props?.disabled && !props?.readed
  return (
    <div className='form-image'>
      <div className='form-image-title'>
        <div>{label || ''}</div>
        {!!label && required && <span className='adm-form-item-required-asterisk'>*</span>}
        <UploadFile
          element={
            bool && (
              <Image
                src='https://s.huiyunban.cn/1746806065627926528.png'
                width={18}
                height={18}
                className='diy-uploader'
              />
            )
          }
          hasToken={hasToken}
          disabled={disabled}
          value={value}
          onConfirm={onConfirm}
          showLoading={showLoading}
        />
      </div>
      <div className='form-image-content'>
        <ImageUploader
          value={value}
          capture={true}
          disableUpload={true}
          deletable={!disabled}
          onChange={onConfirm}
          onDelete={onDelete}
          beforeUpload={beforeUpload}
          upload={upload}
          multiple
          maxCount={limit}
          onCountExceed={(exceed) => {
            Toast.show(`最多选择 ${limit} 张图片，你多选了 ${exceed} 张`)
          }}
        />
      </div>
    </div>
  )
}

FormImage.transform = (value: any, config: any) => {
  if (Array.isArray(value) && value.length > 0) {
    if (config?.limit === 1) {
      return value?.[0]?.object_key || value?.[0]
    }
    return value?.map((i: any) => i?.object_key || i?.oss_key)
  }
  if (typeof value === 'string') {
    return value
  }
  if (typeof value === 'object') {
    return value?.object_key || value?.oss_key
  }
  return value
}

FormImage.convertTo = async (value: any, config: any) => {
  if (Array.isArray(value) && value.length > 0) {
    if (config?.limit === 1) {
      return value?.[0]?.object_key || value?.[0]?.oss_key || value?.[0]
    }
    const { data: fileData } = await batchDownloadSigns({
      params: value?.map((i: any) => ({ object_key: i?.object_key || i?.oss_key || i })),
      hasToken: config.hasToken === undefined ? true : config.hasToken
    })
    return fileData
  }
  if (typeof value === 'string') {
    return value
  }
  if (typeof value === 'object') {
    return value?.object_key || value?.oss_key
  }
  return value
}

export default FormImage
