import { action, makeObservable, observable } from 'mobx'
import { createContext } from 'react'

// import MenuOrigin from '@/pages/Home/components/Area/menu.origin.json'
import { getTreeKeyData } from '@/utils/index'

import { getPermissionApi } from '../services'
/**
 * 目前页面权限码只控制下面包含的
 */
const MenuOrigin = {
  data: [
    {
      code: 'HYB_MOVE_ATTENDANCE_CALENDAR_MENU',
      jump: '/attendance/staffDaily'
    },
    {
      code: 'HYB_MOVE_MOBILE_SCHEDULING_MENU',
      jump: '/schedule/advanced'
    },
    {
      code: 'HYB_MOVE_TEAM_DAILY_ATTENDANCE_MENU',
      jump: '/attendance/teamDaily'
    },
    {
      code: 'HYB_MOVE_TEAM_MONTHLY_ATTENDANCE_MENU',
      jump: '/attendance/teamMonthly'
    },
    {
      code: 'HYB_MOVE_MY_SCHEDULE_MENU',
      jump: '/schedule/my'
    },
    {
      code: 'HYB_MOVE_TEAM_SCHEDULING_MENU',
      jump: '/schedule/team'
    }
  ]
}
// const CurrentAppId = [11011, 11013, 11009]
// 功能点权限获取
class AuthStore {
  constructor() {
    makeObservable(this)
    // this.fetchMap()
  }
  // 指定对应的接口字段 select or tree
  fieldNames = { label: 'name', value: 'value' }
  @observable status: 'pending' | 'resolve' | 'reject' = 'pending'
  @observable appMap: any = {}
  @observable menuTree: any[] = [] // 菜单树
  @observable menuPath: string[] = [] // 菜单path
  @observable curApp: string[] = [] // 菜单code
  @observable curFunction: string[] = [] // 功能code

  @action
  fetchMap() {
    // await getPermission({}).then(
    //   ({ success, data }) => {
    //     if (success) {
    //       this.curApp = data
    //         .filter((x) => CurrentAppId.includes(x.application_code))
    //         .map((y) => y.functions)
    //         .flat()
    //     }
    //   },
    //   (error: any) => {
    //     console.log('error: ', error)
    //   }
    // )

    getPermissionApi({ business_type: 'MOBILE' }).then(
      ({ success, data }: any) => {
        if (success) {
          const [meun, func, path] = getTreeKeyData(data)
          this.menuTree = data
          this.menuPath = path
          this.curApp = meun
          this.curFunction = func.flat()
          setTimeout(() => {
            this.status = 'resolve'
          }, 500)
        }
      },
      (error: any) => {
        this.status = 'reject'
        console.log('error: ', error)
      }
    )
  }

  @action
  clearCurApp = () => {
    this.curApp = []
  }

  // 获取path权限验证 > boolean
  getPathAuth = (path: string) => {
    const isControledPage = MenuOrigin.data.findIndex((item: any) => item.jump === path) > -1
    const isAuthed =
      this.menuPath?.findIndex(
        (_path: string) => _path === path || _path === `/pages/H5/index?path=${path}`
      ) > -1
    return isControledPage ? isAuthed : true
  }

  // 获取code权限验证 > boolean
  getCodeAuth = (code: string) => {
    return code ? this.curApp?.includes(code) : true
  }

  getCodeFun = (code: string) => {
    // debugger
    return code ? this.curFunction?.includes(code) : true
  }
}
export const authStore = new AuthStore()
export const authStoreWithContext = createContext(authStore)
export default authStore
