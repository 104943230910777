import axios, { AxiosRequestConfig, AxiosInstance } from 'axios'

import { defaultApiDomain } from '../env'

import { repReject, repResolve, reqReject, reqResolve } from './interceptors'

export function createAxios(options: AxiosRequestConfig = {}): AxiosInstance {
  const defaultOptions = {
    baseURL: defaultApiDomain || '/',
    timeout: 12000
  }
  const service: AxiosInstance = axios.create({
    ...defaultOptions,
    ...options
  })
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  service.interceptors.request.use(reqResolve, reqReject)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  service.interceptors.response.use(repResolve, repReject)
  return service
}

export const defAxios = createAxios()

export const updateBaseURL = (env: string) => {
  defAxios.defaults.baseURL = defaultApiDomain || '/'
}
export default defAxios
