import { apiEnvMap, PRD_HOST } from '@/config'

import { hybStorage, getEnvFormStorage, setEnvToStorage } from './storage'

/**
 * TODO: 已废弃用域名判断环境
 * @param url
 * @returns
 */
export function getEnvironmentFromUrl(url: string) {
  const matches = url.match(/cloud-test\.huiyunban\.cn\/([\w-]+)-(\w+)/)
  if (url.includes('huiyunban.cn')) {
    if (matches && matches.length === 3) {
      const [, , env] = matches
      switch (env) {
        case 'qa1':
        case 'qa2':
        case 'qa3':
        case 'qa4':
          return env
        case 'sandbox':
          return 'sandbox'
        default:
          return 'qa1'
      }
    } else if (url.includes(PRD_HOST)) {
      return 'formal'
    }
    return 'qa1'
  } else {
    if (matches && matches.length === 3) {
      const [, , env] = matches
      switch (env) {
        case 'qa1':
        case 'qa2':
        case 'qa3':
        case 'qa4':
          return env
        case 'sandbox':
          return 'sandbox'
        default:
          return 'qa1'
      }
    } else if (url.includes(PRD_HOST)) {
      return 'formal'
    }
    return 'qa1'
  }
}

// 是否是生产环境
export const isPrdEnv = __ENV__ === 'formal'

/**
 *  获取 项目环境
 * @returns qa1、qa2、qa3、qa4、sandbox
 */
export function getProjectEnv() {
  // 不是生产
  return __ENV__ || getEnvFormStorage() || 'qa1'
}

/**
 * 接口域名
 */
export const defaultApiDomain = apiEnvMap[getProjectEnv()]

/**
 *  跳转到H5域名(小程序相关)
 */
export const getH5Host = () => {
  const env = getProjectEnv()
  return env === 'formal'
    ? 'https://app.huiyunban.cn/'
    : `https://cloud-test.huiyunban.cn/fe-saas-h5-${env}/`
}

// 非线上环境 初始化api接口环境
export const initApiDevEnv = (newEnv?: string) => {
  // 线上环境，不初始化环境
  if (!isPrdEnv) {
    const env = getProjectEnv()
    setEnvToStorage(newEnv || env)
    if (newEnv && newEnv !== env && env !== 'formal') {
      // location.reload()
    }
  }
}
