import { Image } from 'antd-mobile'
import { FC } from 'react'

import { useEventManager } from '@/components/EventManagerProvider'
import { toPx } from '@/utils'

const getFirstUrl = (props: any, fieldName = 'url') => {
  const imageUrls = props.dataSource?.[props.imageFieldName]
  if (Array.isArray(imageUrls) && imageUrls.length) {
    return imageUrls?.[0]?.[fieldName]
  }
  return imageUrls?.[fieldName] || imageUrls
}

export default (Component: FC) => {
  return (props: any) => {
    const eventManager = useEventManager()
    const src = getFirstUrl(props, props?.urlFieldName) || ''
    const openImage = () => {
      if (src) {
        props.onOpen && props.onOpen({ src })
        !props.onOpen && eventManager?.emit('ScrollList.openImage', { src })
      }
    }
    return (
      <div className='hyb-image-item'>
        <div
          className='hyb-image-item-left'
          style={props.imageWidth ? { width: `${toPx(props.imageWidth)}px` } : {}}
        >
          <Image
            style={props.imageWidth ? { width: `${toPx(props.imageWidth - 20)}px` } : {}}
            src={src}
            fallback={props.imageNotFound ? <img src={props.imageNotFound} alt='' /> : undefined}
            alt={props?.imageAlt || '暂无图片'}
            onClick={openImage}
          />
        </div>
        <div className='hyb-image-item-right'>
          <Component {...props} />
        </div>
      </div>
    )
  }
}
