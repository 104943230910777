import { Dialog } from 'antd-mobile'
import './index.less'

interface IProps {
  data?: any[]
  title?: string
  [key: string]: any
}

const alertDialog = ({ data = [], title, alertTitle }: IProps) => {
  Dialog.alert({
    className: 'alert-dailog',
    title: data?.length > 0 ? alertTitle || '排班失败员工及原因：' : '',
    content:
      title && !data?.length ? (
        <div className='tips-content-box'>{title || ''}</div>
      ) : (
        <div className='schedule-failure-info-box'>
          {data?.map((planItem: any, planIndex: number) => (
            <div className='item-info-box' key={planIndex}>
              <div className='top-style'>
                {planItem?.employee_name || ''} {planItem?.person_number || ''}{' '}
                {planItem?.schedule_date || planItem?.date_str || ''}
              </div>
              <div className='bottom-style'>{planItem?.error_msg || planItem?.reason || ''}</div>
            </div>
          ))}
        </div>
      )
  })
}
export default alertDialog
