import dayjs from 'dayjs'

import { CalendarCascaderEnum } from './type'

const useSettingColumn = (setState: Function) => {
  const pickColumns = (props: {
    columns?: Array<{ label: string; value: string }>[]
    mapColumnsFn: Function
    config: CalendarCascaderEnum
    timeColumns: Array<{ label: string; value: string }>[]
  }) => {
    let _columns: Array<{ label: string; value: string }>[]
    let flatColumns: Array<{ label: string; value: string }> = []
    const { columns, mapColumnsFn, timeColumns, config } = props
    _columns = mapColumnsFn({ timeColumns, config })
    if (columns) {
      _columns = columns
    }

    flatColumns = _columns.flat()
    return {
      _columns,
      flatColumns
    }
  }

  const renderValue = (props: any) => {
    const { value, columns, flatColumns } = props
    if (Array.isArray(value)) {
      return value
    }
    if (!value) {
      return []
    }
    if (typeof value === 'string') {
      if (columns.length === 1) {
        return [value, flatColumns?.[0].value]
      }
      if (columns.length === 2) {
        return [value, '00:00']
      }
    }
    return value
  }

  const renderTitle = (props: {
    columns: Array<{ label: string; value: string }>[]
    value: any
    flatColumns: Array<{ label: string; value: string }>
    config: CalendarCascaderEnum
    list: any
  }) => {
    const { columns, list, value, flatColumns } = props
    const calendarTitle: string = list?.[0] || dayjs()?.format('YYYY-MM-DD')
    let cascaderTitle: string = ''
    let cellTitle: string[] = []
    const bool: boolean = Array.isArray(value)
    if (columns.length === 1) {
      cascaderTitle =
        flatColumns.find((x: any) => x?.value === list?.[1])?.label || flatColumns?.[0]?.label
      cellTitle = [value?.[0], flatColumns.find((x: any) => x?.value === value?.[1])?.label].filter(
        Boolean
      )
    }
    if (columns.length === 2) {
      // 时间格式下显示，如果其他情况取 list?.[1]在columns中取
      cascaderTitle = list?.[1] || `${columns?.[0]?.[0].label}:${columns?.[1]?.[0].label}`
      cellTitle = [value?.[0], value?.[1]].filter(Boolean)
    }
    return {
      calendarTitle,
      cascaderTitle,
      cellTitle
    }
  }

  const renderDefaultValue = (props: {
    columns: Array<{ label: string; value: string }>[]
    value: any
    flatColumns: Array<{ label: string; value: string }>
  }) => {
    const { value, columns, flatColumns } = props
    const calendarDefaultValue = Array.isArray(value) && value?.[0] ? dayjs(value?.[0]) : dayjs()
    let cascaderDefaultValue: string[] = []
    if (columns.length === 1) {
      cascaderDefaultValue = [value?.[1] || flatColumns?.[0].value].filter(Boolean)
    }
    if (columns.length === 2) {
      const arr = value?.[1]?.split(':')
      cascaderDefaultValue = [
        arr?.[0] || columns?.[0]?.[0].value,
        arr?.[1] || columns?.[1]?.[0]?.value
      ]
    }
    return {
      calendarDefaultValue,
      cascaderDefaultValue
    }
  }

  const renderDefaultList = (props: any) => {
    const { calendarDefaultValue, cascaderDefaultValue, columns, value, onConfirm } = props
    let list: string[] = []
    if (columns.length === 1) {
      list = [calendarDefaultValue, cascaderDefaultValue?.[0]]
    }

    if (columns.length === 2) {
      list = [calendarDefaultValue, cascaderDefaultValue?.join(':')]
    }
    if (typeof value === 'string' && value) {
      onConfirm?.(list)
    }
    return { list }
  }

  const columnChange: any = (props: {
    val: string[]
    columns: Array<{ label: string; value: string }>[]
    list: string[]
    setState: Function
  }) => {
    const { columns, list, val } = props
    if (columns.length === 1) {
      setState({
        list: [list?.[0], val?.[0]]
      })
    }
    if (columns.length === 2) {
      setState({
        list: [list?.[0], val.join(':')]
      })
    }
  }

  return {
    pickColumns,
    renderTitle,
    renderValue,
    renderDefaultValue,
    renderDefaultList,
    columnChange
  }
}

export default useSettingColumn
