import { List } from 'antd-mobile'
import classNames from 'classnames'
import React from 'react'

import { toExpressionValue } from '@/utils'
import './index.less'

const HCell: React.FC<any> = ({
  value,
  disabled,
  editform,
  expression,
  showPlaceholder,
  divide,
  readed,
  ...props
}: any) => {
  const text = expression
    ? toExpressionValue({ key: expression, data: props.form?.getFieldsValue() || {}, divide })
    : value || props.defaultValue || (disabled || readed ? '' : props.placeholder || '请选择')
  return (
    <List.Item
      isLink={!disabled}
      className={classNames('h-cell', {
        'h-cell-disabled': disabled,
        'h-cell-read': readed,
        'h-cell-placeholder': !(value || props.defaultValue),
        'h-cell-editform': editform && !!value,
        'h-cell-editform-placeholder': editform
      })}
      {...props}
    >
      {Array.isArray(text) ? text.join('；') : text}
    </List.Item>
  )
}

export default HCell
