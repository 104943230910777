import { useRequest, useSetState } from 'ahooks'
import { isEqual } from 'lodash-es'
import { useEffect } from 'react'

import { toJson } from '@/utils'
import service from '@/utils/service'

import { StatusCode } from '../enum'

import usePcTransform from './usePcTransform'

// 免登录通用表单
export const getCommonForm = (data: any): Promise<any> =>
  service({
    method: 'get',
    url: '/hrm/other/form',
    data: { ...data, paged: false, enable_status: 1 }
  })

/**
 * 免登录获取通用表单
 * @param code 表单code
 * @param qr_code_id 免登录扫码id
 */
export default (code: string, qr_code_id?: string) => {
  const [{ option, optionStatus }, setState] = useSetState<{
    option: any
    optionStatus: string
  }>({
    option: undefined,
    optionStatus: StatusCode.pending
  })
  const { transform } = usePcTransform()
  const { run: runOptions } = useRequest(() => getCommonForm({ form_code: code, qr_code_id }), {
    manual: true,
    onSuccess: ({ success, data }) => {
      if (success && data?.h5_form_info) {
        console.log('获取option:', toJson(data?.h5_form_info))
        const _option = transform(toJson(data?.h5_form_info))
        setState({ option: _option, optionStatus: StatusCode.done })
      } else {
        setState({ optionStatus: StatusCode.error })
      }
    }
  })
  const setFormOptionItem = (formInfo: any, items: { [key: string]: any }, extraName?: string) => {
    if (extraName) {
      // TODO
    } else {
      const info = formInfo
      Object.entries(items).forEach(([key, value]) => {
        const columns = key.split('.')
        if (columns?.length === 2) {
          const blockIndex = info.findIndex((item: any) =>
            item.form_list?.find((item: any) => isEqual(item.formItemProps?.name, columns))
          )
          const itemIndex = info?.[blockIndex]?.form_list?.findIndex((item: any) =>
            isEqual(item.formItemProps?.name, columns)
          )
          try {
            if (blockIndex > -1 && itemIndex > -1) {
              info[blockIndex].form_list[itemIndex] = {
                ...info[blockIndex].form_list[itemIndex],
                ...value
              }
            } else {
              throw new Error(`设置表单项找不到 ${key}`)
            }
          } catch (e) {
            // TODO
          }
        }
      })
      return info
    }
  }
  const getFormOptionItem = (formInfo: any, key: string, extraName?: string) => {
    if (extraName) {
      // TODO
    } else {
      const info = formInfo
      const columns = key.split('.')
      if (columns?.length === 2) {
        const blockIndex = info.findIndex((item: any) =>
          item.form_list?.find((item: any) => isEqual(item.formItemProps?.name, columns))
        )
        const itemIndex = info?.[blockIndex]?.form_list?.findIndex((item: any) =>
          isEqual(item.formItemProps?.name, columns)
        )
        try {
          if (blockIndex > -1 && itemIndex > -1) {
            return info[blockIndex].form_list[itemIndex]
          } else {
            throw new Error(`设置表单项找不到 ${key}`)
          }
        } catch (e) {
          // TODO
        }
      }
      return {}
    }
  }
  useEffect(() => {
    if (qr_code_id) {
      runOptions()
    }
  }, [])
  return {
    option,
    optionStatus,
    initOption: runOptions,
    setFormOptionItem,
    getFormOptionItem
  }
}
