import { Form } from 'antd-mobile'
import dayjs from 'dayjs'
import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import NewEditForm from '@/components/NewEditForm'
import jobStore from '@/store/components/entryJob'
import { FiledObjCalculate } from '@/utils'

import HPopup from '../../HYBVant/HPopup'

import { dateFormatMap, getFormButtons } from './constant'
import {
  dynamicFields,
  dynamicFieldsEditDetail,
  dynamicFieldsDelete,
  dynamicFieldsList
} from './service'

import './index.less'

const Popup: React.FC<any> = (props) => {
  const { modelPage, changeModelPageConfig } = useLocalObservable(() => jobStore)
  const [formIt] = Form.useForm()
  const currentFiledsRef: any = useRef(null)
  const [formInfo, setFormInfo] = useState<any[]>([])
  const [dyFields, setFields] = useState<any>([])
  const { onCancel, popupData = {}, employee_id } = props

  const { code = '', isAsync = false, title = '', qrCodeId } = popupData

  const { status = '', list = [], cachejson } = modelPage?.[code] || {}

  const fetchDynamicFieldsList = async () => {
    const { code: asCode, data } = await dynamicFieldsList(employee_id || '', code || '')
    if (asCode === 2000) {
      changeModelPageConfig(code, {
        list: (data || [])?.map((x: any) => ({ ...x?.field_value, id: x?.id }))
      })
      onCancel()
    }
  }

  console.log(popupData, 'employee_id', popupData)

  const fetchDynamicFields = async () => {
    const { data } = await dynamicFields(
      {
        paged: false,
        employee_dynamic_field_group_codes: [code]
      },
      qrCodeId
    )
    const form_list: any[] = []
    if (data && Array.isArray(data)) {
      setFields(data)
      data.reverse().forEach((x) => {
        const {
          type,
          name,
          extra_info: { formItemProps, elementProps }
        } = x
        form_list.push({
          type,
          formItemProps: {
            ...formItemProps,
            name
          },
          elementProps: {
            ...elementProps,
            ...FiledObjCalculate(type === 'Select', {
              dataList: elementProps?.options?.map((y: any) => ({
                ...y,
                name: y?.label,
                text: y?.label,
                id: y?.value
              }))
            }),
            ...FiledObjCalculate(type === 'InputNumber', {}),
            ...FiledObjCalculate(type === 'DatePicker', {
              min: new Date('1960-01-01'),
              type: x?.extra_info?.elementProps?.picker,
              format: dateFormatMap?.[x?.extra_info?.elementProps?.picker]
            })
          }
        })
      })
    }
    const _formInfo: any = [
      {
        title,
        top: true,
        key: '11',
        form_list
      }
    ]
    setFormInfo(_formInfo)
    changeModelPageConfig(code, {
      cachejson: _formInfo
    })
  }

  const save = async (fieldValues: any) => {
    const mapFiled = fieldValues
    console.log(fieldValues, 'fieldValues', formInfo)
    const _params: Record<string, any> = { ...mapFiled }
    for (const item of formInfo?.[0]?.form_list || {}) {
      const name: any = item?.name || item?.formItemProps?.name
      if (item?.type === 'DatePicker') {
        _params[name] = dayjs(_params[name]).format(
          dateFormatMap?.[item?.elementProps?.picker || 'date']
        )
      } else if (item?.type === 'Select') {
        _params[`${name}_name`] = item?.elementProps?.options?.find(
          (y: any) => y?.value === mapFiled[name]
        )?.label
        _params[name] = mapFiled[name]
      } else _params[name] = mapFiled[name]
    }
    const field = {
      ..._params
    }
    if (isAsync) {
      const params = {
        employee_id,
        employee_dynamic_field_group_code: code,
        field_value: _params,
        id: currentFiledsRef.current?.id
      }
      await dynamicFieldsEditDetail(params)
      fetchDynamicFieldsList()
      return
    }
    status === 'Edit' &&
      changeModelPageConfig(code, {
        list: list?.map((x: any) => {
          if (x.active) return field
          return x
        })
      })
    status === 'Add' &&
      changeModelPageConfig(code, {
        list: [...list, field]
      })
    onCancel()
  }

  const setFieldValues = () => {
    const fields = list?.find((x: any) => x.active)
    currentFiledsRef.current = fields
    formIt.setFieldsValue({
      ...fields
    })
  }

  useEffect(() => {
    status === 'Edit' && formInfo?.length && setFieldValues()
  }, [status, formInfo])

  const deleteInfo = async () => {
    const search = () => {
      status === 'Edit' &&
        changeModelPageConfig(code, {
          list: list?.filter((x: any) => !x.active)
        })
      onCancel()
    }
    const id = (list || []).find((x: any) => x.active)?.id
    if (isAsync) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { code } = await dynamicFieldsDelete([id])
      if (code === 2000) fetchDynamicFieldsList()
      return
    }
    search()
  }

  useEffect(() => {
    fetchDynamicFields()
  }, [])

  return (
    <HPopup
      title={`${status === 'Add' ? '添加' : ''}${title}`}
      hasOk={false}
      className='lg edit-popup-wrap'
      {...props}
    >
      <NewEditForm
        formInst={formIt}
        formInfo={cachejson ?? formInfo}
        formButtons={getFormButtons({ hasDelete: status === 'Edit', deleteInfo })}
        onFinish={save}
      />
    </HPopup>
  )
}

export default observer(Popup)
