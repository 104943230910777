import { FlowType, FlowListType, FlowOperaType } from '../types'

export const EOvertimeReasonsData = [
  {
    name: '其他',
    value: '0',
    text: '其他',
    id: '0'
  },
  {
    name: '生产需要',
    value: '1',
    text: '生产需要',
    id: '1'
  }
]

// 进出方向
export const EEntryExitDirectionData = [
  {
    text: '自动判断',
    name: '自动判断',
    value: 'AUTO',
    id: 'AUTO'
  },
  {
    text: '进',
    name: '进',
    value: 'IN',
    id: 'IN'
  },
  {
    text: '出',
    name: '出',
    value: 'OUT',
    id: 'OUT'
  }
]

export const EReplacementCardnReasonsData = [
  {
    name: '其他',
    value: 'OTHER',
    text: '其他',
    id: 'OTHER'
  },
  {
    name: '公司原因',
    value: 'COMPANY_REASON',
    text: '公司原因',
    id: 'COMPANY_REASON'
  },
  {
    name: '个人原因',
    value: 'PERSONAL_REASON',
    text: '个人原因',
    id: 'PERSONAL_REASON'
  }
]

export const SubmitAgain = 'SubmitAgain'

export const RequiredFile = (applyItem: string) => {
  return [
    'SYSTEM_SICK_LEAVE',
    'SYSTEM_WEDDING_LEAVE',
    'SYSTEM_PRENATAL_EXAMINATION_LEAVE',
    'SYSTEM_MATERNITY_LEAVE',
    'SYSTEM_PARENTAL_LEAVE',
    'SYSTEM_NURSING_LEAVE',
    'SYSTEM_PREGNANCY_LOSS_LEAVE',
    'SYSTEM_OCCUPATIONAL_INJURY_LEAVE',
    'SYSTEM_CHILDCARE_LEAVE',
    'SYSTEM_ONLY_CHILD_NURSING_LEAVE',
    'SYSTEM_CHANGE',
    'SYSTEM_TRANSFER',
    'SYSTEM_DEMOTION',
    'SYSTEM_PROMOTION'
    // 'SYSTEM_JOB_TRANSFER'
  ].includes(applyItem)
}

export const MapPersonnelChange: Record<string, string> = {
  SYSTEM_DEMOTION: 'DEMOTION',
  SYSTEM_PROMOTION: 'PROMOTION',
  SYSTEM_JOB_TRANSFER: 'POSITION_TRANSFER'
}
export const RequiredPost = (applyItem: string) => {
  return ['SYSTEM_TRANSFER'].includes(applyItem)
}

export const StatusImg: any = {
  APPROVED: 'https://s.huiyunban.cn/1764935140150087680.png', // 已同意
  NOT_APPROVED: 'https://s.huiyunban.cn/1764935289085628416.png', // 已拒绝
  2: 'https://s.huiyunban.cn/1764935405574033408.png', // 直接同意
  3: 'https://s.huiyunban.cn/1764935579008503808.png', // 已退回
  4: 'https://s.huiyunban.cn/1764935698462281728.png', // 直接拒绝
  5: 'https://s.huiyunban.cn/1764935823997800448.png', // 已抄送
  UNDER_APPROVAL: 'https://s.huiyunban.cn/1764935956948848640.png', // 审批中
  TO_BE_SUBMITTED: ''
}

export const ObjectToKeyValueString = (obj: any) => {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&')
}
export function objectToQueryString(obj: any) {
  return Object.keys(obj)
    ?.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')
}
export const availableStockRander = (str: any) => {
  let textStr: any = ''
  switch (str) {
    case 'SYSTEM_YEARLY_HOLIDAY':
      textStr = '年假可用库存'
      break
    case 'SYSTEM_FLEXIBLE_LEAVE':
      textStr = '调休可用库存'
      break
    case 'SYSTEM_CHILDCARE_LEAVE':
      textStr = '育儿假可用库存'
      break
    case 'SYSTEM_ONLY_CHILD_NURSING_LEAVE':
      textStr = '库存'
      break
    default:
      textStr = '可用库存'
  }
  return textStr
}

export const reasonsTitle = ({ applyItem = '', bigApplyItem = '' }) => {
  let textStr: any = ''
  if (bigApplyItem === 'ASK_FOR_LEAVE') {
    textStr = '请假原因'
  } else if (applyItem === 'SYSTEM_LEAVING') {
    textStr = '外出原因'
  } else if (applyItem === 'SYSTEM_BUSINESS_TRIP') {
    textStr = '公差原因'
  } else if (
    ['SYSTEM_SUPPORT', 'SYSTEM_LOAN'].includes(applyItem) ||
    bigApplyItem === 'PERSONNEL'
  ) {
    textStr = '备注'
  } else {
    textStr = '原因'
  }
  return textStr
}

export const attachmentsTitle = ({ applyItem = '', bigApplyItem = '' }) => {
  let textStr: any = ''
  if (applyItem === 'SYSTEM_SICK_LEAVE') {
    textStr = '挂号证明/医疗证明'
  } else if (applyItem === 'SYSTEM_WEDDING_LEAVE') {
    textStr = '结婚证'
  } else if (applyItem === 'SYSTEM_PRENATAL_EXAMINATION_LEAVE') {
    textStr = '结婚证/产检证明'
  } else if (['SYSTEM_MATERNITY_LEAVE', 'SYSTEM_CHILDCARE_LEAVE'].includes(applyItem)) {
    textStr = '子女出生证明'
  } else if (applyItem === 'SYSTEM_PARENTAL_LEAVE') {
    textStr = '出生证明/结婚证'
  } else if (applyItem === 'SYSTEM_NURSING_LEAVE') {
    textStr = '出生证明/胎数证明'
  } else if (applyItem === 'SYSTEM_PREGNANCY_LOSS_LEAVE') {
    textStr = '流产证明'
  } else if (applyItem === 'SYSTEM_OCCUPATIONAL_INJURY_LEAVE') {
    textStr = '工伤证明'
  } else if (applyItem === 'SYSTEM_ONLY_CHILD_NURSING_LEAVE') {
    textStr = '父母住院证明'
  } else {
    textStr = '附件'
  }
  return textStr
}

export const MapRequestKey = (type: string, code: string) => {
  if (type === 'PERSONNEL' || type === 'CHANGE_SCHEDULE') return code
  return type
}

export const FlowApplyStatusMap = {
  0: 'TO_BE_SUBMITTED',
  1: 'UNDER_APPROVAL',
  2: 'APPROVED',
  3: 'NOT_APPROVED',
  4: 'REVOKED'
}

export const FlowListOperaMap: any = {
  [FlowType.Apply]: {
    [FlowListType.Nosubmitted]: {
      cardAction: [
        {
          text: '删除',
          color: '#FF4D4F',
          flowOperaType: FlowOperaType.Delete
        },
        {
          text: '提交',
          flowOperaType: FlowOperaType.Submit
        }
        // {
        //   text: '详情',
        //   color: '#0256FF',
        //   flowOperaType: FlowOperaType.Detail
        // }
      ],
      status: [
        {
          text: '待提交',
          className: 'info'
        }
      ],
      detailAction: [],
      batchAction: [
        {
          text: '批量删除',
          display: '删除',
          flowOperaType: FlowOperaType.Delete,
          flowType: FlowType.Apply,
          className: 'danger'
        },
        {
          text: '批量提交',
          display: '提交',
          flowOperaType: FlowOperaType.Submit,
          flowType: FlowType.Apply
        }
      ],
      flowType: FlowType.Apply,
      flowListType: FlowListType.Nosubmitted
    },
    [FlowListType.Approval]: {
      cardAction: [
        {
          text: '催办',
          flowOperaType: FlowOperaType.Urging,
          display: 'current_node_allow_urge'
        }
        // {
        //   text: '详情',
        //   color: '#0256FF',
        //   flowOperaType: FlowOperaType.Detail
        // }
      ],
      status: [
        {
          text: '审批中',
          color: '#FAAD14',
          className: 'warning'
        }
      ],
      detailAction: [
        {
          text: '再次提交',
          display: 'submit_again_flag',
          // icon: 'https://s.huiyunban.cn/1764943528678395904.png',
          icon: 'resubmit',
          flowOperaType: FlowOperaType.SubmitAgain
        },
        {
          text: '撤销',
          code: 'HYB_MOVE_MY_APPLICATION_REVOKE',
          // icon: 'https://s.huiyunban.cn/1764945543395545088.png',
          icon: 'revoke',
          flowOperaType: FlowOperaType.Revoke
        },
        {
          text: '催办',
          display: 'current_node_allow_urge',
          // icon: 'https://s.huiyunban.cn/1764945694105276416.png',
          icon: 'remind',
          flowOperaType: FlowOperaType.Urging
        }
      ],
      batchAction: [
        {
          text: '批量催办',
          display: '催办',
          flowOperaType: FlowOperaType.Urging,
          flowType: FlowType.Apply
        }
      ],
      flowType: FlowType.Apply,
      flowListType: FlowListType.Approval
    },
    [FlowListType.Passed]: {
      cardAction: [
        // {
        //   text: '详情',
        //   color: '#0256FF',
        //   flowOperaType: FlowOperaType.Detail
        // }
      ],
      status: [
        {
          text: '已通过',
          color: '#52C41A',
          className: 'success'
        }
      ],
      detailAction: [
        {
          text: '上传附件',
          display: 'can_upload_attachment',
          // icon: 'https://s.huiyunban.cn/1767387980437131264.png',
          icon: 'upload-file',
          flowOperaType: FlowOperaType.File,
          flowType: FlowType.Apply
        },
        {
          text: '再次提交',
          display: 'submit_again_flag',
          // icon: 'https://s.huiyunban.cn/1764943528678395904.png',
          icon: 'resubmit',
          flowOperaType: FlowOperaType.SubmitAgain
        },
        {
          text: '撤销',
          code: 'HYB_MOVE_MY_APPLICATION_REVOKE',
          // icon: 'https://s.huiyunban.cn/1764945543395545088.png',
          icon: 'revoke',
          flowOperaType: FlowOperaType.Revoke
        }
      ],
      flowType: FlowType.Apply,
      flowListType: FlowListType.Passed
    },
    [FlowListType.Fail]: {
      cardAction: [
        // {
        //   text: '详情',
        //   color: '#0256FF',
        //   flowOperaType: FlowOperaType.Detail
        // }
      ],
      status: [
        {
          text: '未通过',
          color: '#FF4D4F',
          className: 'danger'
        }
      ],
      detailAction: [
        {
          text: '上传附件',
          display: 'can_upload_attachment',
          // icon: 'https://s.huiyunban.cn/1767387980437131264.png',
          icon: 'upload-file',
          flowOperaType: FlowOperaType.File,
          flowType: FlowType.Apply
        },
        {
          text: '再次提交',
          display: 'submit_again_flag',
          // icon: 'https://s.huiyunban.cn/1764943528678395904.png',
          icon: 'resubmit',
          flowOperaType: FlowOperaType.SubmitAgain
        }
      ],
      flowType: FlowType.Apply,
      flowListType: FlowListType.Fail
    },
    [FlowListType.Revoke]: {
      cardAction: [
        // {
        //   text: '详情',
        //   color: '#0256FF',
        //   flowOperaType: FlowOperaType.Detail
        // }
      ],
      status: [
        {
          text: '已撤销',
          className: 'disabled'
        }
      ],
      detailAction: [
        {
          text: '上传附件',
          display: 'can_upload_attachment',
          icon: 'https://s.huiyunban.cn/1767387980437131264.png',
          flowOperaType: FlowOperaType.File,
          flowType: FlowType.Apply
        },
        {
          text: '再次提交',
          display: 'submit_again_flag',
          icon: 'https://s.huiyunban.cn/1764943528678395904.png',
          flowOperaType: FlowOperaType.SubmitAgain
        }
      ],
      flowType: FlowType.Apply,
      flowListType: FlowListType.Revoke
    }
  },
  [FlowType.Approval]: {
    [FlowListType.Processing]: {
      cardAction: [
        {
          text: '拒绝',
          color: '#FF4D4F',
          flowOperaType: FlowOperaType.Reject
        },
        {
          text: '同意',
          flowOperaType: FlowOperaType.Agree
        }
        // {
        //   text: '详情',
        //   color: '#0256FF',
        //   flowOperaType: FlowOperaType.Detail
        // }
      ],
      detailAction: [
        {
          text: '更多',
          extend: true,
          type: 'more',
          // icon: 'https://s.huiyunban.cn/1765285063399837696.png'
          icon: 'more'
        },
        {
          text: '修改表单',
          // icon: 'https://s.huiyunban.cn/1765285195193257984.png',
          icon: 'modify',
          flowOperaType: FlowOperaType.Modify,
          display: 'current_node_form_allow_edit'
        }
      ],
      extendAction: [
        {
          text: '撤销',
          code: 'HYB_MOVE_MY_APPROVAL_REVOKE',
          // icon: 'https://s.huiyunban.cn/1764945543395545088.png',
          icon: 'revoke',
          flowOperaType: FlowOperaType.Revoke
        },
        {
          text: '加签',
          // icon: 'https://s.huiyunban.cn/1765290041770250240.png',
          icon: 'add-sign',
          flowOperaType: FlowOperaType.Countersign,
          display: 'current_node_allow_counter_sign'
        },
        {
          text: '转签',
          // icon: 'https://s.huiyunban.cn/1765290229800898560.png',
          icon: 'transfer-sign',
          flowOperaType: FlowOperaType.Transfer,
          display: 'current_node_allow_transfer'
        },
        {
          text: '回退',
          // icon: 'https://s.huiyunban.cn/1765290369659965440.png',
          icon: 'withdraw',
          flowOperaType: FlowOperaType.Back,
          display: 'current_node_allow_revert'
        }
      ],
      detailOtherAction: [
        {
          text: '拒绝',
          className: 'apply-form-action-reject',
          flowType: FlowType.Approval,
          flowOperaType: FlowOperaType.Reject,
          display: 'can_reject'
        },
        {
          text: '同意',
          className: 'apply-form-action-agree',
          flowType: FlowType.Approval,
          flowOperaType: FlowOperaType.Agree,
          display: 'can_pass'
        }
      ],
      status: [
        {
          text: '审批中',
          color: '#FAAD14'
        }
      ],
      flowType: FlowType.Approval,
      flowListType: FlowListType.Processing
    },
    [FlowListType.Processed]: {
      cardAction: [
        // {
        //   text: '详情',
        //   color: '#0256FF',
        //   flowOperaType: FlowOperaType.Detail
        // }
      ],
      status: [
        {
          text: '审批中',
          color: '#FAAD14',
          status: 'UNDER_APPROVAL'
        },
        {
          text: '未通过',
          color: '#FF4D4F',
          status: 'NOT_APPROVED'
        },
        {
          text: '已通过',
          color: '#52C41A',
          status: 'APPROVED'
        },
        {
          text: '已撤销',
          status: 'REVOKED'
        }
      ],
      detailAction: [
        {
          text: '撤销',
          code: 'HYB_MOVE_MY_APPROVAL_REVOKE',
          // icon: 'https://s.huiyunban.cn/1764945543395545088.png',
          icon: 'revoke',
          flowOperaType: FlowOperaType.Revoke
        }
      ],
      flowType: FlowType.Approval,
      flowListType: FlowListType.Processed
    },
    [FlowListType.Ccmy]: {
      cardAction: [
        // {
        //   text: '详情',
        //   color: '#0256FF',
        //   flowOperaType: FlowOperaType.Detail
        // }
      ],
      status: [
        {
          text: '审批中',
          color: '#FAAD14',
          status: 'UNDER_APPROVAL'
        },
        {
          text: '未通过',
          color: '#FF4D4F',
          status: 'NOT_APPROVED'
        },
        {
          text: '已通过',
          color: '#52C41A',
          status: 'APPROVED'
        },
        {
          text: '已撤销',
          status: 'REVOKED'
        }
      ],
      flowType: FlowType.Approval,
      flowListType: FlowListType.Ccmy
    }
  }
}

// eslint-disable-next-line complexity
export const RenderFlowCardSubTitle = (props: Record<string, any>): any => {
  const { apply_type_code, apply_category_code, start_date, end_date, detail_map, initiate_mode } =
    props

  let arr1: string[] = []
  let arr2: string[] = []
  let arr3: string[] = []
  let arr4: string[] = []
  let arr5: string[] = []

  if (apply_category_code === 'ASK_FOR_LEAVE' || apply_category_code === 'BUSINESS_TRIP') {
    arr1 = ['开始时间', start_date || '']
    arr2 = ['结束时间', end_date || '']
    arr3 = ['实际总时长(小时)', detail_map?.total_duration || '']
  }

  if (apply_category_code === 'OVERTIME') {
    arr1 = ['开始时间', start_date || '']
    arr2 = ['结束时间', end_date || '']
    arr3 = ['实际总时长(小时)', detail_map?.total_duration || '']
    // arr3 = ['休息/用餐时长', detail_map?.eat_duration || '']
  }

  if (apply_category_code === 'REISSUE_CLOCK' && initiate_mode === 'LOOP_MODE') {
    arr1 = ['开始时间', start_date || '']
    arr2 = ['结束时间', end_date || '']
    arr3 = ['补卡时间', detail_map?.reissue_clock_time || '']
    arr4 = ['原因类型', detail_map?.reason_type_name || '']
  }

  if (apply_category_code === 'REISSUE_CLOCK' && initiate_mode !== 'LOOP_MODE') {
    arr1 = ['日期', detail_map?.reissue_clock_date || '']
    arr2 = ['补卡时间', detail_map?.reissue_clock_time || '']
    arr3 = ['原因类型', detail_map?.reason_type_name || '']
  }

  if (apply_type_code === 'SYSTEM_LOAN') {
    const list = detail_map?.supported_employee_name || []
    const title = (() => {
      if (list.length === 0) {
        return ''
      }
      return `${list
        .slice(0, 3)
        .map?.((item: any) => item)
        ?.join?.('、')}${list?.length > 3 ? `等${list?.length}人` : ''}`
    })()
    arr1 = ['被借组织', detail_map?.from_org_id_name || '']
    arr2 = ['借调员工', title]
    arr3 = ['开始时间', start_date || '']
    arr4 = ['结束时间', end_date || '']
  }

  if (apply_type_code === 'SYSTEM_SUPPORT' || apply_type_code === 'SYSTEM_ALLOCATION') {
    const length: number = detail_map?.supported_employee_name?.length
    const str = `等${length}人`
    arr1 = [
      '支援的员工',
      `${detail_map?.supported_employee_name?.slice(0, 2)?.map((x: any) => x)}${
        length > 2 ? str : ''
      }` || ''
    ]
    arr2 = ['开始时间', start_date || '']
    arr3 = ['结束时间', end_date || '']
  }

  if (
    apply_type_code === 'SYSTEM_CHANGE' ||
    apply_type_code === 'SYSTEM_DEMOTION' ||
    apply_type_code === 'SYSTEM_PROMOTION' ||
    apply_type_code === 'SYSTEM_JOB_TRANSFER'
  ) {
    arr1 = ['异动类型', detail_map?.movement_type_name || '']
    arr2 = ['异动原因', detail_map?.movement_reason_name || '']
    arr3 = ['生效日期', detail_map?.effective_date || '']
  }

  if (apply_type_code === 'SYSTEM_RESIGNATION') {
    arr1 = ['计划离职日期', detail_map?.planned_departure_date || '']
    arr2 = ['实际入职日期', detail_map?.actual_entry_date || '']
    arr3 = ['离职原因', detail_map?.reason_code_name || '']
  }

  if (apply_type_code === 'SYSTEM_EMPLOYMENT') {
    arr1 = ['入职员工姓名', detail_map?.name || '']
    arr2 = ['入职日期', detail_map?.entry_date || '']
    arr3 = ['入职部门', detail_map?.org_name || '']
  }

  if (apply_type_code === 'SYSTEM_TRANSFER') {
    arr1 = ['实际入职日期', detail_map?.actual_entry_date || '']
    arr2 = ['试用期(天)', detail_map?.probation_period || '']
    arr3 = ['实际转正日期', detail_map?.actual_confirmation_date || '']
  }

  if (apply_type_code === 'TIAO_SCHEDULE') {
    arr1 = ['调班日期', detail_map?.schedule_date || '']
    arr2 = [
      '申请日班次',
      [detail_map?.schedule_info].map(mapSchedule)?.[0].schedule_shift_label || ''
    ]
    arr3 = [
      '调班班次',
      [{ ...detail_map?.to_shift_info, schedule_shift_name: detail_map?.to_shift_info?.name }].map(
        mapSchedule
      )?.[0].schedule_shift_label || ''
    ]
  }

  if (apply_type_code === 'HUAN_SCHEDULE') {
    arr1 = ['申请日期', detail_map?.schedule_date || '']
    arr2 = [
      '申请班次',
      [detail_map?.schedule_info].map(mapSchedule)?.[0].schedule_shift_label || ''
    ]
    arr3 = ['换班对象', detail_map?.target_employee_name || '']
    arr4 = ['换班日期', detail_map?.target_schedule_date || '']
    arr5 = [
      '换班班次',
      [detail_map?.target_schedule_info].map(mapSchedule)?.[0].schedule_shift_label || ''
    ]
  }

  if (apply_type_code === 'EXTERNAL_HELPER' || apply_type_code === 'INTERNAL_HELPER') {
    arr1 = ['使用部门', detail_map?.org_name || '']
    arr2 = ['申请人数共计', String(detail_map?.apply_total_num || 0)]
    arr3 = ['宴会日期', detail_map?.banquet_date || '']
    arr4 = ['宴会名称', detail_map?.banquet_name || '']
  }
  return [['单据编号', props?.code || ''], arr1, arr2, arr3, arr4, arr5]
}

export const MapApplyStatus: any = {
  UNDER_APPROVAL: 1,
  APPROVED: 2,
  NOT_APPROVED: 3,
  REVOKED: 4
}

export const GetApplyItem = (applyInitiate: string) => {
  if (applyInitiate === 'AGENT_INITIATED') return 'agent_common_form_code'
  if (applyInitiate === 'INDIVIDUAL_INITIATED') return 'personal_common_form_code'
  return ''
}

export const FindCurrentOrg = (id: any, list: any[]) => {
  for (let i = 0; i < list.length; i++) {
    const x = list[i]
    if (id === x.id) return x
    if (x?.children && Array.isArray(x?.children) && x?.children.length > 0) {
      const found: any = FindCurrentOrg(id, x?.children)
      if (found) return found
    }
  }
  return null
}

export const ApprovalFlowListType: Record<number, FlowListType> = {
  0: FlowListType.Processing,
  1: FlowListType.Processed,
  2: FlowListType.Ccmy
}

export const EApplyData = [
  {
    label: '单次模式',
    value: 'SINGLE_CYCLE_MODE'
  },
  {
    label: '规律模式',
    value: 'LOOP_MODE'
  }
]
// 时间模式
export const ETimeData = [
  {
    label: '全天模式',
    value: 'ALL_DAY_MODE'
  },
  {
    label: '时段模式',
    value: 'TIME_PERIOD_MODE'
  },
  {
    label: '时长模式',
    value: 'DURATION_MODE'
  },
  {
    label: '半天模式',
    value: 'HALF_DAY_MODE'
  }
]

export const mapSchedule = (x: any) => {
  const display_time = x?.time_interval_list
    ?.map((y: any) => {
      if (y?.display_time) return y?.display_time
      return `${y?.start_datetime}-${y?.end_datetime}`
    })
    ?.join(';')
  if (x?.schedule_shift_code === 'HXB-00001') {
    return { ...x, schedule_shift_label: `${display_time}` }
  }
  if (x?.id === '0' || x?.schedule_shift_id === '0') {
    return { ...x, schedule_shift_label: x?.schedule_shift_name }
  }
  return { ...x, schedule_shift_label: `${x?.schedule_shift_name}(${display_time})` }
}
