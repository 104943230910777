import { useSetState } from 'ahooks'
import { CheckList, Popup, SearchBar, SafeArea } from 'antd-mobile'
import { isEqual } from 'lodash-es'
import { observer } from 'mobx-react'
import { FC, useEffect, useMemo } from 'react'

// ====================== 内部公共
import { HErrorBlock } from '@/components'
import HCell from '@/components/NewEditForm/UILibrary/HYBVant/HCell'
import { isNullOrUndefined } from '@/utils'
import './index.less'

interface IProps {
  columns?: any[]
  disabled?: boolean
  onChange?: Function
  fieldNames?: any
  isSearch?: boolean
  title?: string
  store?: any
  value?: any
}

const PublicPicker: FC<IProps> = ({
  value = [],
  columns = [],
  isSearch = false,
  disabled = false,
  title = '',
  fieldNames = { value: 'value', label: 'name' },
  onChange
}) => {
  const [{ show, selectedValue, searchText }, setState] = useSetState<any>({
    show: false,
    selectedValue: null,
    searchText: ''
  })
  useEffect(() => {
    if (!isNullOrUndefined(value)) {
      columns?.forEach((item: any) => {
        if (value.includes(item[fieldNames?.value])) {
          setState({ selectedValue: item?.[fieldNames?.label] })
        }
      })
    } else if (!isEqual(value, selectedValue)) {
      setState({ selectedValue: undefined })
    }
  }, [value, columns])
  const onClick = () => {
    !disabled &&
      setState({
        show: true
      })
  }
  const onClose = () => {
    setState({
      show: false
    })
  }
  const filteredItems = useMemo(() => {
    if (searchText) {
      return columns.filter((item: any) => item?.[fieldNames?.label]?.includes(searchText))
    } else {
      return columns
    }
  }, [columns, searchText])
  return (
    <>
      <HCell value={selectedValue} onClick={onClick} disabled={disabled} />
      <Popup
        visible={show}
        closeOnMaskClick={true}
        position='bottom'
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px'
        }}
        className='public-picker-popup'
        onClose={() => onClose()}
      >
        <div className='public-picker-popup-content'>
          <div className='public-picker-popup-content-header'>
            <div className='public-picker-popup-content-header-close' onClick={() => onClose()}>
              取消
            </div>
            <div className='public-picker-popup-content-header-title'>选择{title}</div>
          </div>
          {isSearch && (
            <div className='public-picker-popup-content-search'>
              <SearchBar
                placeholder='请输入'
                value={searchText}
                onChange={(v) => {
                  setState({
                    searchText: v
                  })
                }}
              />
            </div>
          )}
          {filteredItems?.length > 0 ? (
            <div className='public-picker-popup-content-list'>
              <CheckList
                value={value || []}
                onChange={(val) => {
                  onChange?.(val)
                  setState({
                    show: false
                  })
                }}
              >
                {filteredItems?.map((item: any) => (
                  <CheckList.Item key={item?.[fieldNames?.value]} value={item?.[fieldNames?.value]}>
                    {item?.[fieldNames?.label]}
                  </CheckList.Item>
                ))}
              </CheckList>
            </div>
          ) : (
            <div className='public-picker-popup-content-not'>
              <HErrorBlock />
            </div>
          )}
          <SafeArea position='bottom' />
        </div>
      </Popup>
    </>
  )
}

export default observer(PublicPicker)
