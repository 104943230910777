import { Toast, Modal } from 'antd-mobile'

import axios, { type InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import CryptoJS from 'crypto-js'

import { getToken, removeToken, clearToast } from '@/utils'

import { bridge } from '../bridge'
import { defaultApiDomain, getH5Host } from '../env'

import loadingCtrl from './loadingCtrl'

interface AxiosRequestCustomConfig<D = any> extends InternalAxiosRequestConfig<D> {
  loading?: boolean
  timestamp?: number
}

interface AxiosCustomResponse<T = any, D = any> extends AxiosResponse<T, D> {
  config: AxiosRequestCustomConfig<D>
}

const { addLoading, removeLoading } = loadingCtrl()

export function reqResolve(config: AxiosRequestCustomConfig): AxiosRequestCustomConfig {
  config.headers = config.headers || {}
  // 防止缓存，给get请求加上时间戳
  if (config.method === 'get') {
    config.params = { ...config.params, t: new Date().getTime() }
  }
  if (config.loading !== false && config.headers?.loading !== false) {
    const timestamp = Date.now()
    config.timestamp = timestamp
    addLoading(`${config.url}_${timestamp}`)
  }

  config.headers['token'] = getToken()
  config.headers['source'] = 'MINI'
  // 否则继续执行请求
  return config
}

export function reqReject(error: Error) {
  return Promise.reject(error)
}
export function repResolve(
  response: AxiosCustomResponse<{ code: number; msg: string; flag?: number; total?: number }>
) {
  const { config, data } = response || {}
  // loading
  if (config.loading !== false && config.headers?.loading !== false) {
    removeLoading(`${config.url}_${config.timestamp}`)
  }
  const { code, msg } = data || {}
  if (code === 0 || code === 2000) {
    return data
  } else if (code === 401) {
    relogin()
  }
  setTimeout(() => {
    Toast.show(msg || '系统错误')
    clearToast()
  }, 1000)
  return Promise.reject(data)
}

export const skipWX = async () => {
  try {
    const appId = 'wx7bfe2af936fc14b0'
    // 小程序jsapi_ticket
    // // 获取 jsapi_ticket
    const ticketResponse = await axios.get(`${defaultApiDomain}/common/mini/jsapi_ticket`)
    const jsapiTicket = ticketResponse?.data
    // 生成 nonceStr 和 timestamp
    const nonceStr = Math.random()?.toString(36)?.substring(2, 15)
    const timestamp = Math.floor(Date.now() / 1000)
    const redirectUrl = window.location.href
    // 当前页面 URL
    const currentUrl = encodeURIComponent(redirectUrl)

    // 拼接字符串
    const string = `jsapi_ticket=${jsapiTicket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${currentUrl}`

    // 生成 SHA-1 签名
    const signature = CryptoJS.SHA1(string).toString(CryptoJS.enc.Hex)
    // 配置微信 JS-SDK
    wx.config({
      debug: false, // 开启调试模式
      appId, // 必填，公众号的唯一标识
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature, // 必填，签名
      jsApiList: ['navigateToMiniProgram', 'switchTab'] // 必填，需要使用的JS接口列表
    })
    // 监听配置成功事件
    wx.ready(() => {
      console.log('微信 JS-SDK 配置成功')
      jumpToMiniProgram()
    })

    // 监听配置失败事件
    wx.error((res: any) => {
      console.error('微信 JS-SDK 配置失败:', res)
    })
  } catch (error) {
    console.log('error-->>', error)
  }
}

export function extractPath(fullUrl: any, baseUrl: any) {
  // 创建URL对象来解析完整URL
  const url = new URL(fullUrl)

  // 获取完整路径
  let path = url?.pathname

  // 确保baseUrl以斜杠结尾
  if (!baseUrl?.endsWith('/')) {
    baseUrl += '/'
  }

  // 将baseUrl转换为绝对路径
  const base = new URL(baseUrl, fullUrl)?.pathname

  // 如果baseUrl存在且是完整路径的一部分，则移除baseUrl部分
  if (path?.startsWith(base)) {
    path = path?.substring(base?.length)
  }

  return path
}
// 跳转到小程序的 登录授权
export const jumpToMiniProgram = () => {
  const windowUrl = window.location.href
  const redirectUrl = extractPath(windowUrl, getH5Host())
  console.log('跳转到小程序的', redirectUrl)
  wx.miniProgram.navigateTo({
    url: `/pages/Login/index?redirectUrl=/${encodeURIComponent(redirectUrl)}`, // 登录
    success(res: any) {
      console.log('跳转成功:', res)
    },
    fail(err: any) {
      console.error('跳转失败:', err)
    }
  })
}
/**
 * 重新登录
 */
function relogin() {
  const isAuthRouter =
    ['/notify/approve', '/entry/signup', '/entry/fast'].findIndex((path: any) =>
      location.pathname.match(path)
    ) === -1
  if (isAuthRouter) {
    if (bridge.isWebView) {
      // 钉钉token过期
      if (getToken()) {
        removeToken()
        location.reload()
      }
    } else if (bridge.isWx) {
      // 微信小程序未登录
      Modal.show({
        content: '登录已过期，请重新登录',
        closeOnAction: true,
        actions: [
          {
            key: 'online',
            text: '立即登录',
            primary: true,
            onClick: () => {
              skipWX()
            }
          }
        ]
      })
    }
  }
}
export function repReject(error: AxiosError) {
  const status = error.response?.status
  const { loading, url, timestamp } = (error?.config || {}) as AxiosRequestCustomConfig
  // loading
  if (loading !== false) {
    removeLoading(`${url}_${timestamp}`)
  }
  if (status && status >= 500) {
    setTimeout(() => {
      Toast.show(error.message || '系统错误')
      clearToast()
    }, 1000)
  } else if (status === 401) {
    relogin()
  } else {
    setTimeout(() => {
      Toast.show(error.message || '系统错误')
      clearToast()
    }, 1000)
  }
  return Promise.reject(error)
}
