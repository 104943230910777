import { CheckOutline } from 'antd-mobile-icons'
import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import { FC, useMemo } from 'react'

import SvgIcon from '@/components/SvgIcon'

import dayjs from '@/utils/dayjs'

import { DATE_TYPE, TodayStr } from '../../const'

import './index.less'
/**
 * 打卡地址
 */
interface IDayProps {
  data?: any
  start?: string
  end?: string
  active?: string
  maxDate?: string
  minDate?: string
  isNoData?: boolean
  isDefault: boolean
  businessData?: any
  statusInfo?: any
  dismissDate?: any
  onClick?: (day: string) => void
}

const Day: FC<IDayProps> = ({
  data,
  start,
  end,
  active,
  isNoData = false,
  maxDate,
  onClick,
  isDefault,
  statusInfo,
  minDate,
  businessData,
  dismissDate
}) => {
  const isDisabled =
    dayjs(data?.date).isBefore(dayjs(start)) ||
    dayjs(data?.date).isAfter(dayjs(end)) ||
    dayjs(data?.date).isAfter(dayjs(maxDate)) ||
    dayjs(data?.date).isBefore(dayjs(minDate)) ||
    [1, 5].includes(businessData?.employee_status) ||
    (dismissDate ? dayjs(data?.date).isAfter(dayjs(dismissDate)) : false)
  const isStatus = (status: number) =>
    (isEmpty(businessData) ? data?.status : businessData?.daily_status || businessData?.status) ===
    status
  // 每日状态 0无上班人员 10已结账 15已确认无异常 16已确认有异常 20未确认且都正常 30未确认且有异常
  const {
    disabled,
    isCheckout,
    isSuccess,
    isFail,
    isHaveSuccess,
    isHaveFail,
    isActive,
    hasEvent,
    isToday,
    isWeekend,
    isHoliday,
    isFreeday
  } = useMemo(
    () => ({
      disabled: isDisabled,
      isCheckout: isStatus(10),
      isSuccess: isStatus(20),
      isHaveSuccess: isStatus(15),
      isHaveFail: isStatus(16),
      isFail: isStatus(30),
      isActive: data?.date === active,
      hasEvent: data?.form_list?.length > 0,
      isToday: data?.date === TodayStr,
      isWeekend: data?.date_type === DATE_TYPE.weekend,
      isHoliday: data?.date_type === DATE_TYPE.holiday,
      // 休息日
      isFreeday: businessData?.relax === 1
      // isDefault: mode === 'week' && shiftList.length === 0
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, businessData, active]
  )

  return (
    <div key={data?.date} className='fcalendar-bd-day-item' onClick={() => onClick?.(data?.date)}>
      <div
        key={data?.date}
        className={classNames({
          'fcalendar-bd-cell-disabled': disabled,
          'fcalendar-bd-cell-active': isActive,
          'fcalendar-bd-cell-success': isSuccess,
          'fcalendar-bd-cell-fail': isFail,
          'fcalendar-bd-cell-event': hasEvent,
          'fcalendar-bd-cell-today': isToday,
          'fcalendar-bd-cell-weekend': isWeekend,
          'fcalendar-bd-cell-holiday': isHoliday,
          'fcalendar-bd-cell-freeday': isFreeday,
          'fcalendar-bd-default-cell': isDefault && !isNoData,
          'fcalendar-bd-cell-no-data': isNoData,
          'fcalendar-bd-cell': !isDefault
        })}
      >
        <span className='fcalendar-bd-cell-text'>{dayjs(data?.date)?.format('D')}</span>
        {(isSuccess || isFail) && (
          <span
            className={classNames({
              'fcalendar-bd-cell-status': true,
              success: isSuccess,
              danger: isFail
            })}
          />
        )}
        {(isHaveSuccess || isHaveFail) && (
          <SvgIcon className='lock-icon' name={isHaveSuccess ? 'lock-green' : 'lock-red'} />
        )}
        {isCheckout && <CheckOutline className='checkout-icon' />}
      </div>
    </div>
  )
}

export default Day
