import { useSetState } from 'ahooks'
import { Checkbox, Radio } from 'antd-mobile'
import { throttle, cloneDeep } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'

import { KeywordHightlight } from '@/components'
import { HSearchBar } from '@/components/NewEditForm/UILibrary/HYBVant'
import HPopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup'

import './index.less'

interface SearchSelectProps {
  visible: boolean
  type: string
  disabled?: boolean
  mode?: string
  onConfirm?: (employee: any[]) => void
  [key: string]: any
}

const SearchSelectPopup: React.FC<SearchSelectProps> = (props) => {
  const {
    config,
    type = '',
    mode = 'multiple',
    onCancel,
    visible,
    onOk,
    title,
    currentList,
    value,
    defaultvalue
  } = props
  const { showCode = true } = config || {}

  const [{ searchStr, popupVisible, selectSelectList, displaySelectList, show }, setState] =
    useSetState<any>({
      searchStr: '',
      popupVisible: false,
      selectSelectList: [],
      displaySelectList: [],
      show: false
    })

  const onChange = async (keyword: string) => {
    const start = throttle(async () => {
      if (!keyword) {
        setState({ searchStr: '', displaySelectList: currentList })
      }

      if (keyword) {
        setState({
          searchStr: keyword,
          displaySelectList: cloneDeep(currentList)?.filter(
            (x: any) => x?.name?.includes(keyword) || x?.code?.includes(keyword)
          )
        })
      }
    }, 800)
    start()
  }

  const confrimSelected = () => {
    // if (selectSelectList?.length === 0) return
    onOk(selectSelectList)
  }

  const renderCount = () => {
    if (mode === 'single') return '1'
    if (mode === 'multiple') return currentList?.length
    return ''
  }

  const renderCurrentList = useMemo(() => {
    const ComponentList: Record<string, any> = {
      multiple: Checkbox.Group,
      single: Radio.Group
    }
    const ComponentChildList: Record<string, any> = {
      multiple: Checkbox,
      single: Radio
    }

    const ComponentValue: Record<string, any[]> = {
      multiple: selectSelectList?.map((x) => x.id),
      single: selectSelectList?.map((x) => x.id)?.[0] || null
    }

    const Component = ComponentList[mode]

    const ComponentChild = ComponentChildList[mode]

    const ComponentProps = {
      value: ComponentValue[mode]
    }
    return (
      <Component {...ComponentProps}>
        {displaySelectList?.map((x: any) => (
          <div key={x.id} className='search-select-content-item'>
            <ComponentChild
              onClick={() => {
                if (mode === 'single') {
                  setState({
                    selectSelectList: [x]
                  })
                  return
                }
                if (selectSelectList?.map((m: any) => m.id)?.includes(x.id)) {
                  setState({
                    selectSelectList: selectSelectList.filter((y: any) => y.id !== x.id)
                  })
                } else {
                  setState({
                    selectSelectList: [...selectSelectList, x]
                  })
                }
              }}
              value={x.id}
            >
              <KeywordHightlight
                text={`${x.name || '-'} ${showCode && x.code ? `(${x.code || '-'})` : ''}`}
                keyword={searchStr}
              />
              {/* <div className='van-ellipsis-1'>{x.name || '-'}({x.code || '-'})</div> */}
            </ComponentChild>
          </div>
        ))}
      </Component>
    )
  }, [selectSelectList])

  useEffect(() => {
    defaultvalue && Array.isArray(defaultvalue) && setState({ selectSelectList: defaultvalue })
  }, [])

  useEffect(() => {
    setState({
      popupVisible: visible
    })
  }, [visible])

  useEffect(() => {
    setState({ displaySelectList: currentList })
  }, [])

  return (
    <HPopup
      visible={popupVisible}
      hasOk={false}
      title={title}
      className='h-popup-search-select'
      bodyClassName='h-popup-search-select-content'
      onOk={confrimSelected}
      onCancel={onCancel}
    >
      <div className='search-select'>
        <div className='search-select-search'>
          <HSearchBar value={searchStr} onChange={onChange} />
        </div>
        <div className='search-select-content'>{renderCurrentList}</div>
        <div className='search-select-selected'>
          <div className='search-select-selected-n' onClick={() => setState({ show: true })}>
            已选择 （
            <span className='search-select-selected-n-i'>{selectSelectList.length || 0}</span>/
            {renderCount()}）
          </div>
          <div className='search-select-selected-confrim' onClick={confrimSelected}>
            确定
          </div>
        </div>
        <HPopup
          title='已选数据'
          visible={show}
          closeOnMaskClick
          showCloseButton
          hasOk={false}
          hasCancel={false}
          onClose={() => {
            setState({ show: false })
          }}
        >
          <div className='personnel-select-sheet'>
            {selectSelectList?.map((x: any) => (
              <div key={x?.id} className='personnel-select-sheet-item'>
                <div className='personnel-select-sheet-item-i'>{x?.name || '-'}</div>
                <div
                  className='personnel-select-sheet-item-ii'
                  onClick={() => {
                    setState({
                      selectSelectList: selectSelectList?.filter((y: any) => y?.id !== x?.id)
                    })
                  }}
                >
                  移除
                </div>
              </div>
            ))}
          </div>
        </HPopup>
      </div>
    </HPopup>
  )
}

export default observer(SearchSelectPopup)
