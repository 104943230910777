import { Form, Popup, SafeArea } from 'antd-mobile'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'

import NewEditForm from '@/components/NewEditForm'

import './index.less'

interface SheetFormProps {
  onOk: (params: any) => void
  onCancel: (params: any) => void
  title: string
  json: any
  visible: boolean
  okText?: string
  initialValues?: any
  readed?: boolean
}

const SheetForm: React.FC<SheetFormProps> = (props) => {
  const {
    onOk,
    title = '',
    okText = '确定',
    json,
    visible,
    onCancel,
    initialValues = {},
    readed = false
  } = props

  const [formIt] = Form.useForm()

  const confirm = async () => {
    const fieldValues = await formIt.validateFields()
    onOk(fieldValues)
  }

  useEffect(() => {
    formIt.setFieldsValue({ ...(initialValues || {}) })
  }, [initialValues])

  return (
    <Popup
      visible={visible}
      onClose={() => onCancel(false)}
      onMaskClick={() => onCancel(false)}
      className='sheet-popup'
    >
      <div className='sheet-form'>
        <div className='sheet-form-action'>
          <div className='sheet-form-action-i' onClick={() => onCancel(false)}>
            取消
          </div>
          <div className='sheet-form-action-ii'>{title}</div>
          <div className='sheet-form-action-i' onClick={confirm}>
            {okText}
          </div>
        </div>
        <div className='sheet-form-edit'>
          <NewEditForm
            formInst={formIt}
            formInfo={json?.formInfo}
            layout='horizontal'
            formButtons={[]}
            readed={readed}
          />
        </div>
        <SafeArea position='bottom' />
      </div>
    </Popup>
  )
}

export default observer(SheetForm)
