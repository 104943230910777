import { Input } from 'antd-mobile'
import React from 'react'

import { isIOS } from '@/utils'

import HCell from '../HCell'
import HInputDebounce from '../HInputDebounce'
import HPopup from '../HPopup'
import usePopup from '../HPopup/hooks/usePopup'
import './index.less'

const HInput: React.FC<any> = (props: any) => {
  const { open, onOpen, onCancel, onOk } = usePopup({})

  return isIOS && props.iosPopup ? (
    <>
      <div className='h-input' onClick={() => !props.disabled && onOpen()}>
        {props?.readed ? (
          <HCell value={props?.value} readed={props.readed} />
        ) : (
          <Input
            placeholder={props.disabled ? '' : '请输入'}
            value={props?.value}
            onChange={(e: any) => props?.onConfirm(e)}
            disabled
          />
        )}
      </div>
      {open && (
        <HPopup
          title=' '
          position='top'
          hasCancel={false}
          show={open}
          onCancel={onCancel}
          onOk={onOk}
        >
          <HInputDebounce
            placeholder='请输入'
            {...props}
            focus
            style={{ margin: 20 }}
            onBlur={() => onCancel()}
          />
        </HPopup>
      )}
    </>
  ) : (
    <HInputDebounce {...props} />
  )
}

export default HInput
