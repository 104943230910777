import { useSetState } from 'ahooks'
import { Popup } from 'antd-mobile'
import classnames from 'classnames'
import { isNil } from 'lodash-es'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useContext, createContext, useRef, useEffect } from 'react'
import { Fragment } from 'react/jsx-runtime'

import { SvgIcon } from '@/components'
import RenderNode from '@/components/NewEditForm/renderNode'
import usePopup from '@/components/NewEditForm/UILibrary/HYBVant/HPopup/hooks/usePopup'
import useElement from '@/hooks/useElement'
import dictStore from '@/store/global/dictStore'
import unifiedStore from '@/store/unified/unifiedStore'
import { isNotEmptyArray } from '@/utils'

import BtnGroup from '../BtnGroup'

// function useOutsideAlerter(ref, callback) {
//   useEffect(() => {
//     /**
//      * Alert if clicked on outside of element
//      */
//     function handleClickOutside(event) {
//       if (ref.current && !ref.current.contains(event.target)) {
//         typeof callback === 'function' && callback(event.target)
//       }
//     }
//     // Bind the event listener
//     document.addEventListener('mousedown', handleClickOutside)
//     return () => {
//       // Unbind the event listener on clean up
//       document.removeEventListener('mousedown', handleClickOutside)
//     }
//   }, [ref])
// }
const dictStoreWithContext = createContext(dictStore)
export default observer(
  ({
    // value,
    onConFirm,
    form,
    form_list,
    globalProps,
    isLowCode,
    isReset
  }: {
    value?: any
    onConFirm: (value: any) => void
    form: any
    form_list: any[]
    globalProps?: any
    isLowCode?: boolean
    isReset?: boolean
  }) => {
    const wrapperRef = useRef(null)
    const btnRef = useRef(null)
    const store = useLocalObservable(() => dictStore)
    const stateStore = useContext(unifiedStore)
    const zidianStore = useContext(dictStoreWithContext)
    const [{ top, formValue }, setState] = useSetState<any>({
      top: 0,
      formValue: {}
    })
    const { open, modalData, onOpen, onCancel } = usePopup({
      afterOk: () => {
        onConFirm(modalData.keyword)
        return Promise.resolve()
      }
    })
    const { transform } = useElement({
      formOption: { formInfo: [{ form_list }] },
      getExtraDicts: store.requestExtraDicts.bind(store)
    })
    const getElementProps = (x: any) => {
      const elementProps = {
        ...x,
        ...x?.elementProps
      }
      if (x?.elementProps?.dictionary || x?.dictionary) {
        const dictionary_list =
          store.getOptions(
            x?.elementProps?.dictionary || x?.dictionary,
            x?.elementProps?.disabledOptions || x?.disabledOptions
          ) || []
        elementProps.dataList = dictionary_list
        elementProps.options = dictionary_list
      }
      return elementProps
    }
    const handleChange = (changed: any) => {
      console.log('handleChange:', changed)
      setState({
        formValue: { ...formValue, ...changed }
      })
    }

    // 表单字短名称
    const fieldNameList = isNotEmptyArray(form_list)
      ? form_list
          .map((fieldItem) => fieldItem?.formItemProps?.name || fieldItem?.name)
          .filter((key) => !!key)
      : []

    // 是否有表单数据
    const hasFieldValues = fieldNameList.some((fieldName) => {
      const { filterValues } = stateStore.filterState || {}
      return !isNil(filterValues?.[fieldName])
    })

    // useOutsideAlerter(wrapperRef, (target) => {
    //   if (btnRef.current && !btnRef.current.contains(target)) {
    //     onCancel()
    //   }
    // })

    console.log('expand:', form_list, stateStore.filterState?.filterValues)
    const handleNext = () => {
      const values = isLowCode ? transform(formValue) : formValue
      stateStore.setFilterState({
        filterValues: { ...stateStore.filterState?.filterValues, ...values }
      })
      onCancel()
    }
    const params = form_list?.reduce(
      (acc: any, fieldItem: any) => {
        const name = fieldItem?.formItemProps?.name || fieldItem?.name
        return { ...acc, [name]: undefined }
      },
      {} as Record<string, null>
    )
    const onOkFrist = () => {
      stateStore.setFilterState({
        filterValues: { ...stateStore.filterState?.filterValues, ...params }
      })
      setState({
        formValue: {}
      })
    }

    // const getElementProps = (x: any) => {
    //   if (x.type === 'DatePicker') {
    //     console.log('x---DatePicker', x)
    //   }
    //   const elementProps = {
    //     ...x?.elementProps,
    //     form: formInstance,
    //     globalProps: { state: {}, option: {}, onAfterChangeExec }
    //   }
    //   const elementDisabled = !isNullOrUndefined(x?.elementProps?.disabled)
    //     ? x?.elementProps?.disabled
    //     : x?.disabled
    //   if (
    //     disabled ||
    //     !isEmpty(elementDisabled) ||
    //     Object.prototype.toString.call(elementDisabled).slice(8, -1) === 'Boolean'
    //   ) {
    //     elementProps.disabled = disabled || hideExec(elementDisabled, state, {})
    //   }
    //   if (x?.elementProps?.dictionary || x?.dictionary) {
    //     const dictionary_list =
    //       store.getOptions(
    //         x?.elementProps?.dictionary || x?.dictionary,
    //         x?.elementProps?.disabledOptions || x?.disabledOptions
    //       ) || []
    //     elementProps.dataList = dictionary_list
    //     elementProps.options = dictionary_list
    //   }
    //   return elementProps
    // }

    useEffect(() => {
      const filterEle = document.querySelector('.js-unified-filter')
      const handleFn = (e: any) => {
        // 筛选按钮执行它自己的事件
        const flag = btnRef?.current?.contains?.(e?.target)
        open && !flag && onCancel()
      }
      if (filterEle) {
        filterEle.addEventListener('click', handleFn)
      }
      return () => {
        if (filterEle) {
          filterEle.removeEventListener('click', handleFn)
        }
      }
    }, [open])
    useEffect(() => {
      if (isReset) {
        setState({
          formValue: {}
        })
      }
    }, [isReset])

    useEffect(() => {
      // 取两者的最大值（有浮动的情况，或者padding的情况)
      const filterWrapEle = document.querySelector('.js-unified-filter-wrapper')
      const filterEle = document.querySelector('.js-unified-filter')
      if (filterWrapEle && filterEle) {
        setState({
          top: Math.max(filterWrapEle?.offsetHeight, filterEle?.offsetHeight) - 2
        })
      }
    }, [])

    useEffect(() => {
      const values = stateStore.filterState?.filterValues
      if (values && open) {
        setState({
          formValue: Object.keys(params).reduce(
            (res, key) => {
              res[key] = values[key]
              return res
            },
            {} as Record<string, null>
          )
        })
      }
    }, [open])

    return (
      <>
        <div
          ref={btnRef}
          className={`unified-filter-icon-wrap ${hasFieldValues ? 'activated' : ''}`}
          onClick={() => {
            open ? onCancel() : onOpen()
          }}
        >
          <SvgIcon className='filter-icon' name={`filter${hasFieldValues ? '-color' : ''}`} />
        </div>
        <Popup
          visible={open}
          closeOnMaskClick
          position='top'
          bodyStyle={{ top: top }}
          getContainer={document.getElementById('unifiedFilterWrap')}
          className='unified-filter-expand-popup'
          onClose={() => onCancel?.()}
        >
          <div className='unified-filter-expand-wrap' ref={wrapperRef}>
            <div className='unified-filter-expand'>
              {form_list.map((x, index) => (
                <Fragment key={index}>
                  {!globalProps?.hideExec(x?.hide, {}, {}) ? (
                    <div
                      key={index}
                      className={classnames('unified-filter-expand-title', {
                        'button-group': x?.type === 'ButtonGroup'
                      })}
                    >
                      <div className='unified-filter-expand-title-text'>
                        {x?.formItemProps?.label || x?.label}
                      </div>
                      <RenderNode
                        type={x.type}
                        elementProps={{
                          ...getElementProps(x),
                          ...(x?.disabled ? { disabled: x.disabled } : {}),
                          ...(x?.elementProps?.dictionary || x?.dictionary
                            ? {
                                dataList:
                                  store.getOptions(
                                    x?.elementProps?.dictionary || x?.dictionary,
                                    x?.elementProps?.disabledOptions || x?.disabledOptions
                                  ) || [],
                                options:
                                  store.getOptions(
                                    x?.elementProps?.dictionary || x?.dictionary,
                                    x?.elementProps?.disabledOptions || x?.disabledOptions
                                  ) || []
                              }
                            : {}),
                          form,
                          value: formValue?.[x?.formItemProps?.name || x?.name],
                          onConfirm: (v: any) =>
                            handleChange({
                              [x?.formItemProps?.name || x?.name]: v
                            }),
                          globalProps: {
                            state: {},
                            option: {},
                            onAfterChangeExec: globalProps?.onAfterChangeExec
                          }
                        }}
                      />
                    </div>
                  ) : null}
                </Fragment>
              ))}
            </div>
            <BtnGroup
              onOkFrist={() => onOkFrist()}
              onOkNext={() => handleNext()}
              text={['重 置', '筛 选']}
              nextLoading={false}
              style={[
                {
                  background: '#fff',
                  color: '#595960',
                  border: '1px solid #E8E8E9'
                },
                {
                  background: '#0256FF'
                }
              ]}
              code={['', '']}
            />
          </div>
        </Popup>
      </>
    )
  }
)
