/**
 * 筛选带id的参数
 * @param params json 对象
 * @returns json with id
 */
export const getIdParam = (params: any) => {
  return Object.entries(params)
    .reduce((obj: Array<string>, [key, value]: [string, unknown]) => {
      if (key?.match('id') && ['string', 'number'].includes(typeof value)) {
        return obj.concat([`${key}=${value}`])
      }
      return obj
    }, [])
    .join('&')
}
