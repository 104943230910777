/* eslint-disable max-nested-callbacks */
import { Loading } from 'antd-mobile'
import { observer, useLocalStore } from 'mobx-react'
import { FC, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { HErrorBlock } from '@/components'

import authStore, { authStoreWithContext } from '@/store/global/authStore'

// 页面没访问权限
const PageNotAuth: FC<any> = ({ children, code, isControl }) => {
  const store = useContext(authStoreWithContext)
  const location = useLocation()
  const codes = store.curApp

  if (code && store.status === 'pending') {
    store.fetchMap()
    return <Loading className='init-loading' />
  }

  if (isControl) {
    return code && !codes.includes(code) ? null : children
  }
  if (codes?.length > 0 && !store.getPathAuth(location.pathname)) {
    return <HErrorBlock status='premission' title='权限消失了，请联系运营配置权限.' />
  }

  return children
}

// 按钮/控件 没操作权限
export const ControlNotAuth: FC<any> = observer(({ children, code }) => {
  const store = useLocalStore(() => authStore)
  return code && !store.getCodeFun(code) ? null : children
})

export default observer(PageNotAuth)
