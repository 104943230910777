import { usePagination, useSetState } from 'ahooks'
import { cloneDeep, isEmpty, isEqual, omit, pick } from 'lodash-es'
import { startTransition, useEffect, useRef, useState } from 'react'
import { useActivate } from 'react-activation'

import service, { IRequest } from '@/utils/service'
import { removeNulls } from '@/utils'

const OmitUseRequestPage = ['current', 'pageSize']
const OmitRequestPage = ['paged,', 'cur_page', 'page_size', 'noloading']
const OmitRequest = ['url', 'method']
export const defaultPageSize = 15
interface IOptions {
  immediate?: any
  [key: string]: any
}

const useScrollList = (request: IRequest, options?: IOptions | undefined) => {
  const [{ dataList, noMore, total, prevParam }, setState] = useSetState<{
    dataList: any[]
    noMore: boolean
    total: number
    prevParam?: any
  }>({
    dataList: [],
    noMore: false,
    total: 0,
    prevParam: {}
  })
  const prevParamRef = useRef<any>(prevParam)
  const prevCurPage = useRef<number>(1)
  const [loading, setLoading] = useState(null as null | boolean)
  // 保存上一次请求参数
  prevParamRef.current = prevParam
  // 是否从缓存中激活
  const replaceRef = useRef<boolean>(false)
  // 重写分页
  const currentPageRef = useRef<number>(1)

  const {
    runAsync: runQuery,
    pagination: { pageSize }
  } = usePagination<any, any>(
    (requestParam?: any) => {
      console.log(requestParam, 'requestParam')
      const _current = Number.isInteger(requestParam?.current)
        ? requestParam.current
        : currentPageRef.current
      // 保存分页数据
      currentPageRef.current = _current

      if (!replaceRef.current) {
        prevCurPage.current = _current
      }
      if (_current === 1 && !replaceRef.current) {
        setState({
          dataList: [],
          noMore: true,
          total: 0
        })
      }
      const urlMethod = pick(requestParam, OmitRequest)
      const { filterState } = options?.stateStore || {}
      const tempData =
        typeof options?.format === 'function'
          ? options?.format(filterState.filterValues)
          : filterState.filterValues
      const cur_page: number = replaceRef.current ? 0 : _current - 1
      const _data = removeNulls(
        omit(
          {
            paged: true,
            cur_page,
            page_size: replaceRef.current ? currentPageRef.current * pageSize : pageSize,
            ...tempData,
            ...request?.data,
            ...prevParamRef.current,
            ...(options?.format && !isEmpty(omit(requestParam, OmitUseRequestPage))
              ? options?.format(requestParam)
              : requestParam)
          },
          OmitUseRequestPage.concat(OmitRequest)
        )
      )
      console.log('_data:', request?.data, prevParamRef.current, _data)
      const omitedData = omit(_data, OmitRequestPage)
      if (!isEqual(omitedData, prevParamRef.current)) {
        const _prevParam = cloneDeep(omitedData)
        setState({ prevParam: _prevParam })
      }
      const serviceParams: any = omit(
        {
          ...request,
          ...urlMethod,
          data: omit(_data, [...(requestParam?.omit || []), ...(_data?.omit || [])])
        },
        [...(requestParam?.omit || [])]
      )
      setLoading(true)
      return service({
        ...serviceParams,
        config: {
          loading: cur_page === 0
        }
      })
        .then(({ success, data, total: _total }: any): any => {
          return {
            total: data?.total || _total,
            [`list|${pageSize}`]: data?.employee_infos,
            success,
            data
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
    {
      manual: true,
      throttleWait: 800,
      defaultPageSize,
      onSuccess: (res: any) => {
        const { success, data, total: _total } = res
        if (success) {
          console.log(data, _total, '_totaldata', replaceRef.current, currentPageRef.current)
          const _data: any = options?.tableFormat ? options?.tableFormat(data) : data
          let list = []
          // 从详情页返回，一次请求之前加载过的数据，替换
          if (replaceRef.current) {
            list = [..._data]
          } else {
            if (currentPageRef.current === 1) {
              list = _data
            } else {
              // 分页总数大于当前已加载的数据，才需要合并数据，修复返回分页问题
              list = dataList?.length < _total ? dataList.concat(_data) : dataList
            }
          }

          if (replaceRef.current) {
            replaceRef.current = false
            // 需要修正current
            currentPageRef.current = prevCurPage.current
          }

          setState({
            dataList: list,
            noMore: _total <= list?.length || !_total,
            total: _total
          })
        }
      }
    }
  )
  const loadMore = () => {
    return runQuery({ current: currentPageRef.current + 1, pageSize })
  }
  const refresh = ({ reserve, ...rest }: any = {}) => {
    let tempParams = {}
    // 是否保留当前分页状态
    if (reserve) {
      replaceRef.current = true
    } else {
      tempParams = { current: 1 }
    }
    return runQuery({ ...tempParams, pageSize, ...rest })
  }
  // const runQueryOrRefresh = (values?: any) => {
  //   const isDiff = !isEqual(values, prevParamRef.current)
  //   if (isDiff) {
  //     return runQuery({ ...values, current: 1 })
  //   }
  //   return runQuery(values)
  // }
  useEffect(() => {
    if (request && options?.immediate) {
      runQuery()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log('prevParam::', prevParamRef.current)

  // 从keepalive激活
  useActivate(async () => {
    replaceRef.current = true
    await runQuery({ ...prevParamRef.current })
  })

  return {
    loading,
    current: currentPageRef.current,
    pageSize,
    noMore,
    total,
    dataList,
    refresh,
    runQuery,
    loadMore
  }
}
export default useScrollList
