import { http } from '@/utils'
import service from '@/utils/service'

export const workFlowTreeApi = (data: any): any => http.post(`/workflow/apply/projects/tree`, data)
// 查询列表
export const queryDataList = (params: any, method: string, url: string) => {
  const method_ = String(method?.toLocaleLowerCase())
  const isGet = method_ === 'get'
  return urlParamsData(url, params, isGet)
}
export const urlParamsData = (url: any, params: any, isGet: any) =>
  service({ url, method: isGet ? 'get' : 'post', data: isGet ? { params } : params })
