/* eslint-disable prettier/prettier */
// ====================== 基础
import { makeAutoObservable, action, observable } from 'mobx'
// ====================== 内部公共组件
import { createContext } from 'react'

// ====================== 内部私有

// ======================  types / emun 声明
import { apiEnvMap } from '@/config'
import {
  ACTIVE_COMPANY_KEY,
  COMPANY_LIST_KEY,
  CORRELATED_SAAS_KEY,
  LOGIN_INFO_KEY,
  PHONE_KEY,
  TOKEN_KEY,
  USER_DATA_KEY
} from '@/constants'
import { getUrlParams } from '@/utils'
import { bridge } from '@/utils/bridge'
import getQuery from '@/utils/getQuery'
import logger from '@/utils/logger'
import { storage, setUserData, hybStorage, getToken, setToken } from '@/utils/storage'

import {
  getUserInfo,
  getTenantList,
  attendanceStockApi,
  untreatedCount,
  menuCommonlyUsedList
} from './services'
import { IUserData, ICompany, LoginInfoType } from './types'
// ======================  end

/** w
 *  全局状态
 */
class Store {
  constructor() {
    makeAutoObservable(this)
  }
  @observable token = storage.get(TOKEN_KEY) || ''
  @observable CompanyList = storage.get(COMPANY_LIST_KEY) || []
  @observable ActiveCompany: ICompany = storage.get(ACTIVE_COMPANY_KEY) || {}
  @observable loginInfo = storage.get(LOGIN_INFO_KEY) || {}
  @observable menuCommonlyUseds = { all: [], commonly_used: [] }
  @observable authData: any[] = []
  @observable isAuth: boolean = storage.get(LOGIN_INFO_KEY)?.mobile || false
  @observable correlatedSaas: any = storage.get(CORRELATED_SAAS_KEY) || null
  @observable phone: any = storage.get(PHONE_KEY) || null // 手机号
  // 用户信息
  @observable userData: IUserData = storage.get(USER_DATA_KEY) || {}

  @observable attendanceStock: any[] = [
    {
      stock_total: 0,
      apply_type_name: '年假'
    },
    {
      stock_total: 0,
      apply_type_name: '调休假'
    }
  ]

  @observable menuCount: any = {}

  // 登录状态
  @action isLogin() {
    return !!this.token || !!getToken()
  }

  // 获取token
  @action get getToken() {
    return this.token || getToken()
  }
  // 获取授权手机号
  @action
  async setPhone(phone?: string) {
    this.phone = phone
    storage.set('phone', phone)
  }
  // 设置token
  @action
  async setToken(token: string) {
    this.token = token
    setToken(token)
  }
  // 设置correlated_saas
  @action
  async setCorrelatedSaas(correlatedSaas: string) {
    this.correlatedSaas = correlatedSaas
    storage.set('correlated_saas', correlatedSaas)
  }
  // 设置登录信息
  @action
  async setLoginInfo(loginInfo: LoginInfoType) {
    this.loginInfo = loginInfo
    this.isAuth = true
    storage.set('loginInfo', loginInfo)
  }

  // 获取企业列表
  @action
  async setTenant_results(CompanyList: ICompany[]) {
    this.CompanyList = CompanyList
    storage.set('CompanyList', CompanyList)
  }
  // 设置选中企业
  @action
  selectedCompany = (selectCompany: ICompany) => {
    this.ActiveCompany = selectCompany
    storage.set('ActiveCompany', selectCompany)
    // this.getUserInfo()
    this.getattendanceStock()
  }

  // 获取用户假期库存
  @action
  async getattendanceStock() {
    const { data } = await attendanceStockApi()
    this.attendanceStock = data
  }

  @action
  async fetchUntreatedCount() {
    const { code, data } = await untreatedCount()
    if (code === 2000) this.menuCount = data
  }

  // 获取常用菜单
  @action
  async getmenuCommonlyUsedList() {
    const { data } = await menuCommonlyUsedList()
    this.menuCommonlyUseds = data
    const all = data?.all || []
    const commonly_used = data?.commonly_used || []
    const list = [...commonly_used, ...all]
    this.authData = list?.map((item: any) => item?.code)
  }
  // 获取用户信息
  @action
  async getUserInfo() {
    const { data } = await getUserInfo()
    this.userData = data || {}
    // storage.set('userData', this.userData)
    setUserData(data)
  }
  // 获取租户列表
  @action
  async getTenantLists(_tenant_id?: string | number) {
    const res = await getTenantList(this.loginInfo)
    this.CompanyList = res?.data
    storage.set('CompanyList', res?.data)
    // logger(JSON.stringify(res?.data) + '企业列表')
    res?.data?.forEach((item: any) => {
      if (item?.tenant_id === this.ActiveCompany?.tenant_id || item?.tenant_id === _tenant_id) {
        this.selectedCompany(item)
      }
    })
  }
  @action
  clearData = () => {
    this.resetData()
  }
  // 重置初始化
  @action
  resetData() {
    this.token = ''
    this.CompanyList = []
    this.ActiveCompany = {}
    this.userData = {}
    this.isAuth = false
    this.correlatedSaas = null
    this.phone = null
    const StorageKeys = [
      'ACTIVE_COMPANY_KEY',
      'COMPANY_LIST_KEY',
      'CORRELATED_SAAS_KEY',
      'LOGIN_INFO_KEY',
      'PHONE_KEY',
      'TOKEN_KEY',
      'USER_DATA_KEY'
    ]
    StorageKeys.forEach((key: string) => storage.remove(key))
  }

  // 从url同步登录
  @action
  async urlLogin() {
    const data = getUrlParams()
    if (data?.token && data?.token !== storage.get('token')) {
      try {
        const { token, mobile, unionid, phone } = data
        this.setToken(token)
        this.isAuth = true
        this.setCorrelatedSaas('true')
        this.setLoginInfo({ mobile, unionid })
        this.setPhone(phone)
        await this.getUserInfo()
        this.ActiveCompany = { tenant_id: Number(data?.tenant_id) }
        await this.getTenantLists(data?.tenant_id)
        this.getmenuCommonlyUsedList()
        // if (['qa1', 'qa2', 'qa3', 'qa4', 'sandbox'].includes(hybStorage.get('dev_env')))
        // location.reload()
      } catch (e) {
        console.error(e)
        logger(e)
      }
    } else if (data?.tenant_id !== `${this.ActiveCompany?.tenant_id}`) {
      await this.getUserInfo()
      this.ActiveCompany = { tenant_id: Number(data?.tenant_id) }
      await this.getTenantLists(data?.tenant_id)
    }
  }

  // h5自动登录
  @action
  async h5AutoLogin(data: any) {
    console.log('h5AutoLogin:', data)
    try {
      const { token, tenant_results, union_id, mobile, correlated_saas } = data
      this.setToken(token)
      this.isAuth = true
      this.setCorrelatedSaas(`${correlated_saas}`)
      this.setLoginInfo({ mobile, unionid: union_id })
      this.setPhone(mobile)
      this.ActiveCompany = tenant_results?.[0]
      storage.set('ActiveCompany', tenant_results?.[0])
      await this.getUserInfo()
      const res = await getTenantList(this.loginInfo)
      this.CompanyList = res?.data
      storage.set('CompanyList', res?.data)
    } catch (e) {
      console.log('h5AutoLogin-error:', e)
      logger(e)
    }
  }

  // 获取code后继续登录
  @action
  async toCodeLogin(corpId: string, dev_env?: string, agentId?: string, code?: string) {
    if (code) {
      console.log('toCodeLogin:', corpId)
      const { success, data } = await bridge.silenceLogin(
        {
          corp_id: corpId,
          code
        },
        dev_env ? { baseURL: apiEnvMap[dev_env || 'formal'] } : undefined
      )
      console.log('silenceLogin:', data)
      if (success && data?.token) {
        await this.h5AutoLogin(data)
        console.log('silenceLogin:', hybStorage)
        hybStorage.set('corp_id', corpId)
        hybStorage.set('agent_id', agentId)
        return Promise.resolve(1)
      } else {
        this.correlatedSaas = data?.correlated_saas
        storage.set('phone', data?.mobile)
        return Promise.reject(0)
      }
    }
  }

  // 外壳webview自动登录
  @action
  async webviewLogin(corpId: string, dev_env?: string, agentId?: string) {
    // console.log('webviewLogin:', location.search, urlParams, hybStorage.get('corp_id'))
    // 未登录：免登录流程（支持：钉钉、飞书、企微）
    const isAutoLogin = corpId && !getToken()
    // 已登录：切换企业/切换应用
    const isSwitchCompany = corpId && getToken() && corpId !== hybStorage.get('corp_id')
    if (isAutoLogin || isSwitchCompany) {
      try {
        await new Promise((resolve, reject) => {
          const code = getQuery('code')
          // 企微oauth2.0：从url获取code
          if (code) {
            console.log('url.code::::', code)
            this.toCodeLogin(corpId, dev_env, agentId, code).then(resolve, reject)
          } else {
            // 其他：从jssdk中获取code
            bridge
              .getAuthCode({
                corpId,
                agentId
              })
              .then(async (_code: any) => {
                console.log('getcode::::', _code)
                this.toCodeLogin(corpId, dev_env, agentId, _code).then(resolve, reject)
              })
              .catch((err: any) => {
                console.log('getAuthCode err:', err)
                reject(err)
              })
          }
        })
      } catch (e) {
        logger(e)
      }
    }
  }
}
const store = new Store()
export const storeWithContext = createContext(store)
export default store

/** unified */
export * from './unified'
