import { Toast } from 'antd-mobile'

import service from '../service'
import { hybStorage } from '../storage'

/**
 * 获取当前浏览器环境
 * @returns 'wx' | 'wxwork' | 'dd' | 'fs' | 'h5'
 */
export const getUserAgent = () => {
  const userAgent = navigator.userAgent || ''
  let ua: 'wx' | 'wxwork' | 'dd' | 'fs' | 'h5' = 'h5'
  if (/DingTalk/.test(userAgent)) {
    ua = 'dd'
  } else if (/MicroMessenger/.test(userAgent) && /wxwork/.test(userAgent)) {
    ua = 'wxwork'
  } else if (/MicroMessenger/.test(userAgent) && /miniProgram/.test(userAgent)) {
    ua = 'wx'
  } else if (/Lark|Feishu/.test(userAgent)) {
    ua = 'fs'
  }
  return ua
}
export function getDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor

  // 检查是否为移动端
  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'iOS'
  }

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  // 如果都不是，则认为是PC端
  return 'PC'
}
export function isInIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    // 如果访问 window.top 抛出异常（例如同源策略限制），则当前页面很可能在一个 iframe 中
    return true
  }
}
/**
 * 回调转换Promise
 * @param fn 函数
 * @param args 传参
 * @param callbackPropNames callback参数名，默认：[success,fail]
 * @returns Promise
 */
export const callbackToPromise = <T = any>(
  fn: Function,
  args?: any,
  callbackPropNames = ['success', 'fail']
): Promise<T> => {
  return new Promise((resolve, reject) => {
    try {
      fn({ ...args, [callbackPropNames[0]]: resolve, [callbackPropNames[1]]: reject })
    } catch (e) {
      reject(e)
    }
  })
}
/**
 * 只调用一次的Promise函数
 * @param fn
 * @returns
 */
export function createSingleCallAsyncFunction<T extends (...args: any[]) => Promise<any>>(
  fn: T
): T {
  let promise: Promise<ReturnType<T>> | null = null

  return async function (...args: Parameters<T>): Promise<ReturnType<T>> {
    if (!promise) {
      promise = fn(...args)
    }

    return promise
  } as T
}
// 通用的 toast 显示函数
export const bridgeToast = (content: string) => content && Toast.show(content)
const storage = {
  set: hybStorage.set,
  get: hybStorage.get
}
export { service, storage }
