import dayjs from 'dayjs'

export const TOKEN_KEY = 'token'

export const HYB_DEV_ENV = 'hyb_dev_env'

export const USER_DATA_KEY = 'userData'

export const COMPANY_LIST_KEY = 'CompanyList'

export const ACTIVE_COMPANY_KEY = 'ActiveCompany'

export const LOGIN_INFO_KEY = 'loginInfo'

export const CORRELATED_SAAS_KEY = 'correlatedSaas'

export const PHONE_KEY = 'phone'

// 日期选择最大值,当前日期后100年
export const DATEPICKER_MAX = dayjs().add(100, 'year').endOf('year').toDate()

// 日期选择最大值,当前日期前100年
export const DATEPICKER_MIN = dayjs().subtract(100, 'year').startOf('year').toDate()

export * as QUERY_KEY_MAP from './queryKey'
