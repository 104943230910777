export const json = (props: any) => {
  const { filed = [], filedValue, handleChange, reshuffleStatus } = props
  return [
    {
      title: '异动项',
      titltProps: {},
      top: true,
      key: '7',
      form_list: [
        {
          type: 'Select',
          formItemProps: {
            label: '异动项',
            name: 'movement_field',
            required: true
          },
          elementProps: {
            dataList: filed.map((x) => ({
              text: x?.type_name,
              name: x?.type_name,
              value: x?.type,
              id: x?.type,
              disabled: x?.type === 'EMPLOYEE_STATUS'
            })),
            handleChange
          }
        },
        {
          type: 'Input',
          disabled: true,
          formItemProps: {
            label: '原数据',
            name: 'original_value',
            required: true
          },
          elementProps: {}
        },
        {
          type: 'Organize',
          hide: filedValue !== 'ORG',
          formItemProps: {
            label: '变更为',
            name: 'changed_value',
            required: true
          },
          elementProps: {
            type: 'changed_value',
            changed_value: {
              selectorParams: {
                with_no_auth: true
              },
              organizeSearchRequestParams: {
                auth: 'NoAuth'
              }
            },
            config: {
              init: reshuffleStatus === 'Edit'
            }
          }
        },
        {
          type: 'SearchSelect',
          formItemProps: {
            label: '变更为',
            name: ['changed_value', 'POST'],
            required: false
          },
          hide: filedValue !== 'POST',
          elementProps: {
            type: 'changed_value.POST',
            mode: 'single',
            popup: 'array',
            config: {
              title: '岗位',
              type: 'Post',
              init: reshuffleStatus === 'Edit',
              fieldNames: { value: 'id', name: 'post_name', code: 'post_code' }
            }
          }
        },
        {
          type: 'SearchSelect',
          formItemProps: {
            label: '变更为',
            name: ['changed_value', 'HRM_POSITION'],
            required: false
          },
          hide: filedValue !== 'HRM_POSITION',
          elementProps: {
            type: 'changed_value.HRM_POSITION',
            mode: 'single',
            popup: 'array',
            config: {
              title: '职务',
              type: 'Position',
              init: reshuffleStatus === 'Edit',
              fieldNames: { value: 'id', name: 'name', code: 'code' }
            }
          }
        },
        {
          type: 'SearchSelect',
          formItemProps: {
            label: '变更为',
            name: ['changed_value', 'POSITION_RANK'],
            required: false
          },
          hide: filedValue !== 'POSITION_RANK',
          elementProps: {
            type: 'changed_value.POSITION_RANK',
            mode: 'single',
            popup: 'array',
            config: {
              title: '职级',
              type: 'Rank',
              init: reshuffleStatus === 'Edit',
              fieldNames: { value: 'id', name: 'name', code: 'code' }
            }
          }
        },
        {
          type: 'Select',
          hide: [
            'ORG',
            'POST',
            'HRM_POSITION',
            'POSITION_RANK',
            'BANK_ADDRESS',
            'SALARY_BANK_NUMBER'
          ].includes(filedValue),
          formItemProps: {
            label: '变更为',
            name: ['changed_value', 'changed_value'],
            required: true
          },
          elementProps: {
            popup: 'array',
            dataList: filed
              .find((x: any) => x.type === filedValue)
              ?.details?.map((y: any) => ({
                ...y,
                id: y.value,
                text: y.name
              }))
          }
        },
        {
          type: 'InputNumber',
          hide: filedValue !== 'BANK_ADDRESS',
          formItemProps: {
            label: '变更为',
            name: ['changed_value', 'BANK_ADDRESS'],
            required: true
          },
          elementProps: {
            type: 'changed_value.BANK_ADDRESS',
            maxLength: 19,
            precision: 0
          }
        },
        {
          type: 'Input',
          hide: filedValue !== 'SALARY_BANK_NUMBER',
          formItemProps: {
            label: '变更为',
            name: ['changed_value', 'SALARY_BANK_NUMBER'],
            required: true
          },
          elementProps: {
            type: 'changed_value.SALARY_BANK_NUMBER',
            maxLength: 100
          }
        }
      ]
    }
  ]
}
