import { Dialog, ImageViewer, Toast } from 'antd-mobile'
import classNames from 'classnames'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useMemo, useRef } from 'react'

import HErrorBlock from '@/components/HErrorBlock'
import CardItem from '@/components/UnifiedPage/components/CardItem'
import ImageItem from '@/components/UnifiedPage/components/CardItem/ImageItem'
import authStore from '@/store/global/authStore'
import { commonNav } from '@/utils'
import service from '@/utils/service'

import usePopup from '../../HYBVant/HPopup/hooks/usePopup'

import { getIdParam } from './utils'
import './index.less'

const ImageCardItem = ImageItem(CardItem)

const RelatedCard = ({
  value = [],
  onChange,
  btn_list,
  formCode,
  formAuthCode,
  formDelete,
  navigationBarTitleText,
  ...props
}: any) => {
  const { open, modalData, onCancel, onOpen } = usePopup({})
  const auth = useLocalObservable(() => authStore)
  const valueRef = useRef(value)
  valueRef.current = value
  const runDelete = ({ dataSource }: any) => {
    if (formDelete?.url && formDelete?.method) {
      const idFieldName = formDelete.idFieldName || 'id'
      return service({
        url: formDelete.url,
        method: 'delete',
        data: {
          [idFieldName]: [dataSource?.id]
        }
      }).then(({ success }: any) => {
        if (success) {
          Toast.show('删除成功')
          const _value = valueRef.current.filter((i: any) => i.id !== dataSource?.id)
          onChange(_value)
        }
      })
    }
    return Promise.reject()
  }
  const onDelete = (data: any) => {
    Dialog.confirm({
      className: 'hyb-com-dialog-wrap',
      title: '确定删除该条数据吗？',
      onConfirm: () => {
        runDelete(data)
      }
    })
  }
  const onClickMap: any = { del: onDelete }
  const btnList = useMemo(() => {
    return btn_list?.map((item: any) => {
      return {
        ...item,
        onClick: item.onClick || onClickMap[item.key]
      }
    })
  }, [btn_list])
  return (
    <div className={classNames('related-card-wrapper', { hasButton: btn_list?.length > 0 })}>
      {(Array.isArray(value) ? value : [])?.map((item: any, index: number) => (
        <ImageCardItem
          key={`card-item-${index}`}
          dataSource={item}
          onClick={() => {
            if (auth.getCodeFun(formAuthCode)) {
              commonNav.navigateTo({
                url: `/common/form/${formCode}?${getIdParam(item)}${navigationBarTitleText ? `'&navigationBarTitleText=${navigationBarTitleText}` : ''}`
              })
            }
          }}
          btn_list={btnList}
          onOpen={onOpen}
          {...props}
        />
      ))}
      {(Array.isArray(value) ? value : []).length === 0 && (
        <div className='unified-scroll-list-no-data'>
          <HErrorBlock size='large' title={props.emptyTitle} description={props.emptyDescription} />
        </div>
      )}
      <ImageViewer image={modalData?.src} visible={open} onClose={onCancel} />
    </div>
  )
}

export default observer(RelatedCard)
