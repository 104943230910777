import { Dialog, Loading, SafeArea, Toast } from 'antd-mobile'
import classNames from 'classnames'
import { ReactNode, useEffect } from 'react'

import { getUrlParams } from '@/utils'

import PageNotAuthorized from '../PageNotAuthorized'
import './index.less'

interface ILayoutProps {
  children: ReactNode
  code?: string
  pageTitle?: string
  styled?: boolean
  styledNoTop?: boolean
  bordered?: boolean
  [key: string]: any
}
export default ({
  children,
  code,
  styled,
  styledNoTop,
  safearea,
  bordered,
  pageTitle,
  deps,
  className
}: ILayoutProps) => {
  const { navigationBarTitleText } = getUrlParams()
  useEffect(() => {
    if (navigationBarTitleText) {
      document.title = navigationBarTitleText
    }
  }, [])

  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle
    }
  }, [pageTitle])

  return (
    <PageNotAuthorized code={code}>
      {styled || styledNoTop || bordered ? (
        <div
          className={classNames('layout-wrapper', {
            'no-top': styledNoTop || bordered,
            bordered,
            [`${className || ''}`]: true
          })}
        >
          {children}
        </div>
      ) : (
        children
      )}
      {safearea && <SafeArea className='page-safe-area' position='bottom' />}
      {deps === false}
      <Dialog />
    </PageNotAuthorized>
  )
}
