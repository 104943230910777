import { Image } from 'antd-mobile'

import uploadImage from '@/utils/bridge/h5origin/uploadImage'

import HInputDebounce from '../../HYBVant/HInputDebounce'

import './index.less'

function InputWithImage(props: any) {
  const { onFileChange, disabled, tips } = props
  const chooseImg = () => {
    // 对于H5环境，使用<input type="file">
    const inputEl = document.createElement('input')
    inputEl.type = 'file'
    inputEl.accept = 'image/*'
    inputEl.multiple = false // 限制只能选择一张图片
    inputEl.click()

    inputEl.onchange = async (event: any) => {
      const files = event.target.files
      if (files.length > 0) {
        const file = files[0]
        const objectKey = await uploadImage(file, false)
        onFileChange([objectKey])
      }
    }
  }
  return (
    <div className='input-with-image'>
      <div className='input-with-image-input'>
        <HInputDebounce placeholder='请输入' {...props} />
      </div>
      <div className='input-with-image-choose' onClick={() => !disabled && chooseImg()}>
        <Image
          className='input-with-image-display-img'
          src='https://s.huiyunban.cn/1792792144277147648.png'
        ></Image>
        <div className='input-with-image-choose-text'>{tips || 'ORC识别'}</div>
      </div>
    </div>
  )
}

export default InputWithImage
