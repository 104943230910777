import { useSetState } from 'ahooks'
import { CheckList, Popup, SearchBar, SafeArea } from 'antd-mobile'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useEffect } from 'react'

// ====================== 内部公共
import BlankBox from '@/components/BlankBox'
import HCell from '@/components/NewEditForm/UILibrary/HYBVant/HCell'

import useSetting from './useSetting'
import './index.less'

interface IProps {
  renderItem?: Function
  dataList?: any[]
  options?: any[]
  disabled?: boolean
  handleChange?: Function
  onChange?: Function
  onConfirm?: Function
  fieldNames?: any
  isSearch?: boolean
  title?: string
  store?: any
  value?: any
  mode?: string // single, multiple
  size?: string
  readed?: boolean
}

function ListSelect({
  value,
  dataList,
  options,
  isSearch = false,
  disabled = false,
  title = '',
  fieldNames = { value: 'value', label: 'name' },
  mode = 'single',
  onChange,
  onConfirm,
  renderItem,
  size,
  handleChange,
  readed = false,
  ...rest
}: IProps) {
  const [{ show, searchText, checkedValue }, setState] = useSetState<any>({
    show: false,
    searchText: '',
    checkedValue: []
  })

  const _onConfirm = onChange || onConfirm

  const { change, renderList, renderSelectedValue } = useSetting()

  const { list } = renderList({ dataList, options, fieldNames, searchText })

  const { selectedValue } = renderSelectedValue({ value, list, mode })

  const bool: boolean = !disabled && !readed

  const onClick = () => {
    bool &&
      setState({
        show: true
      })
  }
  const onClose = () => {
    setState({
      show: false
    })
  }

  useEffect(() => {
    setState({ checkedValue: mode === 'single' ? [value].filter(Boolean) : value || [] })
  }, [value])

  console.log(value, list, checkedValue, 'ListSelect>>>>')

  return (
    <>
      {renderItem ? (
        renderItem(onClick)
      ) : (
        <HCell value={selectedValue} readed={readed} onClick={onClick} disabled={disabled} />
      )}

      <Popup
        visible={show}
        closeOnMaskClick={true}
        position='bottom'
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px'
        }}
        className={classNames('public-picker-popup', { sm: size === 'sm' })}
        onClose={() => onClose()}
      >
        <div className='public-picker-popup-content'>
          <div className='public-picker-popup-content-header'>
            <div className='public-picker-popup-content-header-i' onClick={() => onClose()}>
              取消
            </div>
            <div className='public-picker-popup-content-header-title'>请选择{title}</div>
            {mode === 'multiple' && (
              <div
                className='public-picker-popup-content-header-i'
                onClick={() => {
                  _onConfirm?.(checkedValue)
                  handleChange?.(checkedValue)
                  setState({
                    show: false
                  })
                }}
              >
                确定
              </div>
            )}
          </div>
          {isSearch && (
            <div className='public-picker-popup-content-search'>
              <SearchBar
                placeholder='请输入'
                value={searchText}
                onChange={(v) => {
                  setState({
                    searchText: v
                  })
                }}
              />
            </div>
          )}
          {list?.length > 0 ? (
            <div className='public-picker-popup-content-list'>
              <CheckList
                multiple={mode === 'multiple'}
                value={checkedValue}
                onChange={(val) =>
                  change({
                    val,
                    mode,
                    setState,
                    handleChange,
                    onChange: _onConfirm
                  })
                }
              >
                {list?.map((item: any) => (
                  <CheckList.Item {...item}>
                    <div className='public-picker-popup-content-list-label'>{item?.label}</div>
                  </CheckList.Item>
                ))}
              </CheckList>
            </div>
          ) : (
            <div className='public-picker-popup-content-not'>
              <BlankBox name='暂无数据' />
            </div>
          )}
          <SafeArea position='bottom' />
        </div>
      </Popup>
    </>
  )
}

ListSelect.transform = (value: any, config: any) => {
  switch (config.mode) {
    case 'multiple':
      return value
    default:
      return Array.isArray(value) ? value?.[0] : value
  }
}
export default observer(ListSelect)
