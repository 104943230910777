import { useSetState } from 'ahooks'
import { ActionSheet, type ActionSheetProps } from 'antd-mobile'
import type { Action } from 'antd-mobile/es/components/action-sheet'
import classNames from 'classnames'
import { useLocalObservable } from 'mobx-react-lite'
import { FC, useEffect, useMemo } from 'react'

import { HErrorBlock } from '@/components'

import { authStore } from '@/store/global/authStore'
import './index.less'
/**
 * 用户卡片
 */
interface HActionSheetProps extends ActionSheetProps {
  dataSource?: any
  [key: string]: string | any
}
const HActionSheet: FC<HActionSheetProps> = ({
  popupClassName = '',
  className = '',
  cancelText = '取消',
  title = '请选择你的处理方式',
  visible,
  extra,
  actions,
  onClose,
  errtip = '暂无配置申请项',
  ...rest
}: any) => {
  const [{ open }, setState] = useSetState({ open: false })
  const { getCodeFun } = useLocalObservable(() => authStore)

  const handleClose = (type: string) => {
    setState({ open: false })
    onClose?.(type)
  }

  // 处理按钮权限
  const tempActions = useMemo(() => {
    const empty = [
      {
        key: 'empty',
        text: (
          <span>
            <HErrorBlock className='staff-daily-no-time-line' title={errtip} />
          </span>
        )
      }
    ]
    const _actions: any[] = (actions || [])
      .filter((x: any) => getCodeFun(x.code || ''))
      ?.map((item: any, index: number) => ({ ...item, key: item.key || index }))
    return _actions?.length ? _actions : empty
  }, [actions, errtip])

  useEffect(() => {
    setState({ open: visible })
  }, [visible])

  return (
    <ActionSheet
      extra={extra || (title && <div className='h-com-actionsheet-title'>{title}</div>)}
      popupClassName={classNames({
        'h-com-actionsheet-popup': true,
        popupClassName: true
      })}
      className={classNames({
        'h-com-actionsheet-wrap': true,
        className: true
      })}
      visible={open}
      actions={tempActions}
      cancelText={cancelText}
      onClose={handleClose}
      {...rest}
    />
  )
}

export default HActionSheet
